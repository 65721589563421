import React, { useState, createContext } from 'react'
import _ from 'lodash'

export const LoadFilterContext = createContext()

export const LoadFilterProvider = props => {
  const [filters, setFilters] = useState([])

  // Filters have the format
  //  { 
  //    attr: attribute (i.e. "score")
  //    filter: value or function for lodash to compare
  //  }

  const filter = (arr, filterObj) => {
    let _arr = _.filter(arr, filterObj || getFilter())
    let filterFunctions = _.filter(filters, f => _.isFunction(f.filter))
    filterFunctions.forEach(f => {
      _arr = _.filter(_arr, f.filter)
    })
    return _arr
  }
  
  // Translates the current list of filters into a filter object
  // to be used in the lodash _.filter function
  const getFilter = () => {
    let lodashFilter = {}
    filters.forEach(f => {
      // functions are special case, not used in _.filter same way
      if (!_.isFunction(f.filter)) { 
        lodashFilter[f.attr] = f.filter
      }
    })
    return lodashFilter
  }

  // Adds a filter to the current filter list, replaces it
  // if it already exists
  const addFilter = (attr, filter) => {
    const newFilter = {
      attr: attr,
      filter: filter
    }
    // remove filter from filters based on attribute
    const newFilters = _.remove(filters, f => f.attr !== attr)
    setFilters(() => [...newFilters, newFilter])
  }

  // Removes a filter based on the attribute provided
  // e.g. 'score' or 'origin_low_pod'
  const removeFilter = (attr) => {
    setFilters(() => _.remove(filters, f => f.attr !== attr))
  }

  // Removes all filters
  const clearFilters = () => {
    setFilters([])
  }

  const filterExceptBy = (arr, attr) => {
    const filterCopy = _.clone(getFilter())
    _.unset(filterCopy, attr) // stateful call to filterCopy to remove specific filter
    return filter(arr, filterCopy)
  }


  // Filters by the filter specified by attr if it's currently in use in this context,
  // otherwise, just returns the original array
  const tryFilterBy = (arr, attr) => {
    let filter
    filters.forEach(f => {
      if (f.attr === attr) {
        filter = _.isFunction(f.filter) ? f.filter : f
      }
    })
    if (filter) {
      return _.filter(arr, filter)
    }
    return arr
  }

  // Returns only one filter based on attr, if it exists
  const getFilterOnly = (attr) => {
    return getFilter()[attr]
  }

  // Returns true if the filter currently contains a filter on attr, false otherwise
  const hasFilter = (_attr) => {
    let found = false
    filters.forEach(f => {
      if (f.attr === _attr) {
        found = true
      }
    })
    return found
  }

  const getAllFilters = () => {
    return filters
  }

  return (
    <LoadFilterContext.Provider value={{
        filter,
        getFilter,
        addFilter,
        removeFilter,
        clearFilters,
        filterExceptBy,
        tryFilterBy,
        getFilterOnly,
        getAllFilters,
        hasFilter
      }}>
      { props.children }
    </LoadFilterContext.Provider>
  )
}
