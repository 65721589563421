import React, { useContext, useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import { Button, Popover, Position, PopoverInteractionKind } from '@blueprintjs/core'
import { SettingsContext } from 'contexts/SettingsContext'
import { LoadSearchContext } from 'contexts/LoadSearchContext'
import CityAutoSuggest from './CityAutoSuggest'
import StudyPicker from './StudyPicker'
import LohPicker from './LohPicker'
import RangeMiles from './RangeMiles'
import ReadOnlyInput from './ReadOnlyInput'
import ScoreInput from './ScoreInput'
import MilesToDays from './MilesToDays'
import QuoteInputAndCalcButton from './QuoteInputAndCalcButton'
import SettingsToggle from './SettingsToggle'
import FetchSearchFormMilesAndExpected from './FetchSearchFormMilesAndExpected'
import ExpectedMouseoverDetails from 'components/loads/ExpectedMouseoverDetails'
import { motion } from 'framer-motion'
import _ from 'lodash'
import styles from 'css/modules/ManualLoadSearch.module.css'

const ManualLoadSearch = forwardRef((props, ref) => {
  const { initSearchOrigin, initSearchDest } = props
  // RangeMiles State
  const [fetchPods, setFetchPods] = useState(false)
  const [fetchDestPods, setFetchDestPods] = useState(false)
  const [fetchEmptyPods, setFetchEmptyPods] = useState(false)
  const [originRange, setOriginRange] = useState(200) // Empty uses originRange as well
  const [destinationRange, setDestinationRange] = useState(100)
  // END RangeMiles State
  
  // LohPicker State
  const [minLOH, setMinLOH] = useState(200)
  // END LohPicker State

  // StudyPicker State
  const [divisions, setDivisions] = useState(null)
  const [selectedStudy, setSelectedStudy] = useState(null)
  const [settingsLoaded, setSettingsLoaded] = useState(false)
  const [equipment, setEquipment] = useState('Vans')
  const [studyResolution, setStudyResolution] = useState(2)
  const [selectedDivision, setSelectedDivision] = useState(null)
  // END StudyPicker State

  // PTA --> Projected Time of Arrival
  const [ptaDate, setPtaDate] = useState(new Date())

  // Empty pods by default until fetched
  const defaultPods = {
    adjacentPods: [],
    podId: { id: null, name: null },
    podIdLow: { id: null, name: null }
  }
 
  const [triedAutoPopulatePods, setTriedAutoPopulatePods] = useState(!initSearchOrigin && !initSearchDest) // will be True if neither provided, meaning don't try
  const [tryAutoSearch, setTryAutoSearch] = useState(!triedAutoPopulatePods) // If not going to try and populate pods, there's no auto searching happening
  
  // SettingsToggle State
  const [quotesOnly, setQuotesOnly] = useState(true)
  const [userSelectedQuotesOrAll, setUserSelectedQuotesOrAll] = useState(false)
  // END SettingsToggle State

  // Quote Input override state
  const [quoteInputOverride, setQuoteInputOverride] = useState(null)
  const quoteInputRef = useRef(null) // used only to clear quote input on new search
  // END Quote Input override state

  const [pods, setPods] = useState(defaultPods)
  const [destPods, setDestPods] = useState(defaultPods)
  const [emptyPods, setEmptyPods] = useState(defaultPods)
  const [emptyCityST, setEmptyCityST] = useState('')
  const [cityST, setCityST] = useState(initSearchOrigin || '')
  const [destCityST, setDestCityST] = useState(initSearchDest || '')
  const emptyInputRef = useRef(null)
  const origInputRef = useRef(null)
  const destInputRef = useRef(null)

  const { settings } = useContext(SettingsContext)
  const { setSearch, clearSearch, searchEmpty } = useContext(LoadSearchContext)
  const { AUTH_URL } = settings
  const cityStRegex = /^[A-Za-z\s]+,\s*[A-Za-z]{2}$/

  const maxLOHRange = 300
  const minLOHRange = 100
  
  // Special convenience bools to use and figure out if the search is ready to go overall
  const noEmpty = (emptyPods.podId.id === null && emptyCityST === '')
  const noOrigin = (pods.podId.id === null && cityST === '')
  const noDestination = (destPods.podId.id === null && destCityST === '')
  const emptyReady = (emptyPods.podId.id !== null && !fetchEmptyPods)
  const originReady = (pods.podId.id !== null) && !fetchPods
  const destinationReady = (destPods.podId.id !== null) && !fetchDestPods
  const originOnlySearchReady = ((originReady || emptyReady) && noDestination)
  const destinationOnlySearchReady = (destinationReady && noOrigin && noEmpty)
  const pointToPointSearchReady = ((originReady || emptyReady) && destinationReady)
  
  // Search Button disables on !searchReady
  const searchReady = (
    (originOnlySearchReady ||
    destinationOnlySearchReady ||
    pointToPointSearchReady) &&
    settingsLoaded
  )

  // Updating the City, ST for origin or destination means
  // pods need to be fetched. 
  const updateCitySt = (inputField) => {
    return (
      newValue => {
        let setCitySTFunc
        let setPodsFunc
        let setFetchPodsFunc
        switch (inputField) {
          case 'origin':
            setCitySTFunc = setCityST
            setPodsFunc = setPods
            setFetchPodsFunc = setFetchPods
            break
          case 'dest':
            setCitySTFunc = setDestCityST
            setPodsFunc = setDestPods
            setFetchPodsFunc = setFetchDestPods
            break
          case 'empty':
            setCitySTFunc = setEmptyCityST
            setPodsFunc = setEmptyPods
            setFetchPodsFunc = setFetchEmptyPods
            break
        }
        setPodsFunc(defaultPods)
        let input = newValue
        if (input.match(cityStRegex)) {
          setFetchPodsFunc(
            (inputField === 'origin' && !fetchPods) ||
            (inputField === 'dest'   && !fetchDestPods) ||
            (inputField === 'empty'  && !fetchEmptyPods)
          )
        } 
        setCitySTFunc(input)
    })
  }
  
  // clears all search fields
  const clearAllSearchFields = () => {
    setPods(defaultPods)
    setDestPods(defaultPods)
    setEmptyPods(defaultPods)
    setEmptyCityST('')
    setCityST('')
    setDestCityST('')
    clearQuoteInputField()
    try {
      // can be null if not loaded yet
      emptyInputRef.current.clear()
      origInputRef.current.clear()
      destInputRef.current.clear()
    } catch (err) {}
    if (!searchEmpty()) {
      clearSearch()
    }
  }

  const clearQuoteInputField = () => {
    if (quoteInputRef.current.hasValue()) {
      quoteInputRef.current.clear()
    }
  }
 

  // Runs the search if called when it has everything ready
  const runSearch = () => {
    // if (!searchReady) {
    //   // Safety if for some reason the search is accidentally called before it's ready to go
    //   return
    // }
    let citySTSplit
    let city
    let state
    let destination = null
    let originSearchPods

    // use empty as truck location for load board search, if no empty use origin as truck location...
    if (emptyPods.podId.id) {
      citySTSplit = emptyCityST.split(/,\s*/)
      city = citySTSplit[0].trim().toUpperCase()
      state = citySTSplit[1].trim().toUpperCase()
      originSearchPods = emptyPods
    }

    if (pods.podId.id && !originSearchPods) { 
      citySTSplit = cityST.split(/,\s*/)
      city = citySTSplit[0].trim().toUpperCase()
      state = citySTSplit[1].trim().toUpperCase()
      originSearchPods = pods
    }

    let destPod = "";
    let origPod = "";

    // Destination only! (it's back)
    if (destinationOnlySearchReady && destPods.podId.id) {
      destination = {
        destAdjacentPods: [], // destPods.adjacentPods,
        destPod: destPods.podId.id,
        destPodIdLow: destPods.podIdLow.id
      }
    }

    if(originSearchPods !== null && typeof originSearchPods !== "undefined" && originSearchPods.podIdLow !== null && typeof originSearchPods.podIdLow !== "undefined") {
      origPod = originSearchPods.podIdLow.name;
    }

    if(destPods !== null && typeof destPods !== "undefined" && destPods.podIdLow !== null && typeof destPods.podIdLow !== "undefined") {
      destPod = destPods.podId.name;
    }

    let truck = { 
      adjacentPods: destinationOnlySearchReady ? null : originSearchPods.adjacentPods,
      tractor: null,
      trailer: null,
      driver: null,
      equipment: equipment,
      city: city,
      zip: null,
      state: state,
      ptaDate: ptaDate,
      orderNum: null,
      podIdLow: destinationOnlySearchReady ? null : originSearchPods.podIdLow.id,
      destination: destination,
      destCitySt: destCityST,
      minLOH: minLOH,
    }

   
    let searchSettings = {
      quotesOnly: quotesOnly,
      minLOH,
      division: selectedDivision,
      study: selectedStudy,
      resolution: studyResolution,
      origPod: origPod,
      destPod: destPod
    }
    setSearch(truck, searchSettings)
  }

  // Used for empty, origin, and destination pods fetching on
  // City, ST input.
  const fetchLocationPods = (citySTString, setPodsFunc, setFetchFunc, range) => {
      let citySTSplit
      let city
      let state
      try {
        citySTSplit = citySTString.split(/,\s*/)
        city = citySTSplit[0].trim()
        state = citySTSplit[1].trim()
      } catch (error) {
        setFetchFunc(false)
      }
      fetch(`${AUTH_URL}/pods`, {
        method: 'POST',
        body: JSON.stringify({
          city: city,
          state: state,
          range: range
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => {
        if(response.ok) {
          response.json().then(pods => {
            if(pods.error) {
              setFetchFunc(false)
            } else {
              setPodsFunc(pods)
              setFetchFunc(false)
            }
          }).catch(error => {setFetchFunc(false)})
        } else {
          throw "error"
        }
      }).catch(error => {setFetchFunc(false)})
  }
  
  useEffect(() => {
    // Auto search populate pods on component mount IF origin and/or dest provided
    if(!triedAutoPopulatePods) {
      setTriedAutoPopulatePods(true)
      if (initSearchOrigin) {
        setFetchPods(true)
      }
      if (initSearchDest) {
        setFetchDestPods(true)
      }
    }
    if (triedAutoPopulatePods && tryAutoSearch && searchReady) {
      setTryAutoSearch(false) // one shot try to search
      runSearch()
      return
    }
    // END Auto search

    if(fetchPods) {
      fetchLocationPods(cityST, setPods, setFetchPods, originRange)
    } else if (fetchDestPods) {
      fetchLocationPods(destCityST, setDestPods, setFetchDestPods, originRange) // originRange === ALL ranges
    } else if (fetchEmptyPods) {
      fetchLocationPods(emptyCityST, setEmptyPods, setFetchEmptyPods, originRange)
    }
  }, [fetchEmptyPods, fetchPods, fetchDestPods, settingsLoaded])

  // LOAD TABLE CLEARING ON SPECIFIC CONTROL CHANGES
  /*useEffect(() => {
    //clearQuoteInputField()
    // if (!searchEmpty()) {
    //   // clears out quote, generally want to reduce usage of useRef!
    //   // however, needed for partially controlled input
    //   // clear Search at Context level
    //   clearSearch()
    // }
  },[
    originRange,
    destinationRange,
    minLOH,
    selectedStudy,
    selectedDivision,
    ptaDate,
    emptyCityST,
    cityST,
    destCityST
  ])*/

  // This is called by a load in the load list to populate fields in the search
  // useRef methods for this component
  useImperativeHandle(ref, () => ({
    populateSearchFields: (origin, destination, quote, pushUpOriginWhenNoEmty) => {
      origInputRef.current.setValue(origin)
      destInputRef.current.setValue(destination)
      // move origin to empty space if origin is populated and empty is blank!
      // pushUpOriginWhenNoEmty is a flag... only want to move origin when not in destOnly search
      if (noEmpty && !noOrigin && pushUpOriginWhenNoEmty) { 
        emptyInputRef.current.setValue(cityST)
        setEmptyCityST(cityST)
        setFetchEmptyPods(true)
      } 
      setCityST(origin)
      setDestCityST(destination)
      setFetchPods(true)
      setFetchDestPods(true)
      if (quote !== null) {
        setQuoteInputOverride(quote)
      } else {
        setQuoteInputOverride(null)
      }
    }
  }))

  return (
    <motion.div
      className={styles.mainContainer}
      initial={{opacity: 0}}
      animate={{opacity: 1}}>
      <FetchSearchFormMilesAndExpected
        empty={emptyCityST}
        origin={cityST}
        originPod={pods.podIdLow.name}
        destination={destCityST}
        study={selectedStudy}
        equipment={equipment}
        resolution={studyResolution}
        division={selectedDivision}
        locationRegex={cityStRegex}>
        {({
          loadingMilesAndExpected,
          emptyMiles,
          ldedMiles,
          expected,
          quoteTarget,
          baseline,
          fuelSurcharge,
          marketRate,
          marketRateLow,
          marketRateHigh,
          confidenceMsg,
          originInputFieldPod,
          destInputFieldPod,
          emptyInputFieldPod
          }) =>
            <>
              <div className={styles.clearSearchButton}><button onClick={() => clearAllSearchFields()}>Reset</button></div>
              <div className={styles.cityStLabel}>City, State</div>
              <div className={styles.settingsLabel}>Settings</div>
              <div className={styles.studyLabel}>Study</div>
              <div className={styles.emptyLabel}>Empty:</div>
              <div className={styles.emptyInput}>
                <CityAutoSuggest
                  ref={emptyInputRef}
                  initialValue={""}
                  onChangeCallback={updateCitySt('empty')}
                  placeholder={"Location"} />
              </div>
              <div className={styles.emptyInputPod}>{emptyPods.podIdLow.name}</div>
              <div className={styles.settingsButton}>
                <SettingsToggle {...{
                    quotesOnly,
                    setQuotesOnly,
                    setUserSelectedQuotesOrAll
                  }} />
              </div>
              <div className={styles.studyPicker}>
                <StudyPicker {...{
                    setEquipment,
                    setStudyResolution,
                    selectedDivision,
                    setSelectedDivision,
                    settingsLoaded,
                    setSettingsLoaded,
                    divisions,
                    setDivisions,
                    selectedStudy,
                    setSelectedStudy
                  }} />
              </div>
              <div className={styles.originLabel}>Orig:</div>
              <div className={styles.originInput}>
                <CityAutoSuggest
                  ref={origInputRef}
                  initialValue={initSearchOrigin || ""}
                  onChangeCallback={updateCitySt("origin")}
                  placeholder={"LoadAt"} />
              </div>
              <div className={styles.originInputPod}>{pods.podIdLow.name}</div>
              <div className={styles.emptyToOriginMiles}>
                <ReadOnlyInput value={emptyMiles} />
              </div>
              <div className={styles.emptyToOriginMilesLabel}>EMTY</div>
              <div className={styles.rangeLabel}>Radius</div>
              <div className={styles.lohLabel}>LOH</div>
              <div className={styles.ldedLabel}>LDED</div>
              <div className={styles.originRangePicker}>
                <RangeMiles
                  origOrDest={'origin'}
                  {...{
                    originRange,
                    destinationRange,
                    cityST,
                    destCityST,
                    emptyCityST,
                    setFetchPods,
                    setFetchDestPods,
                    setFetchEmptyPods,
                    setOriginRange,
                    setDestinationRange,
                    minLOHRange,
                    maxLOHRange
                }}/>
              </div>
              <div className={styles.lohPicker}>
                <LohPicker {...{ minLOH, setMinLOH }} />
              </div>
              <div className={styles.destLabel}>Dest:</div>
              <div className={styles.destInput}>
                <CityAutoSuggest
                  ref={destInputRef}
                  initialValue={initSearchDest || ""}
                  onChangeCallback={updateCitySt("dest")}
                  placeholder={"Anywhere"} />
              </div>
              <div className={styles.destInputPod}>{destPods.podIdLow.name}</div>
              <div className={styles.origToDestMiles}>
                <ReadOnlyInput value={ldedMiles} />
              </div>
              { /**
               <div className={styles.destRangePicker}>
                <RangeMiles
                  origOrDest={'destination'}
                  {...{
                    originRange,
                    destinationRange,
                    cityST,
                    destCityST,
                    emptyCityST,
                    setFetchPods,
                    setFetchDestPods,
                    setFetchEmptyPods,
                    setOriginRange,
                    setDestinationRange,
                    minLOHRange,
                    maxLOHRange
                }}/>
              </div> */}
              <div className={styles.quotesOnlyRadio}>
                {/* <RadioGroup
                  onChange={() => {
                    setQuotesOnly(!quotesOnly)
                    setUserSelectedQuotesOrAll(true)
                  }}
                  selectedValue={quotesOnly ? 'qo' : 'all'}>
                    <Radio label={'Quotes'} value={'qo'} />
                    <Radio label={'All'} value={'all'} />
                </RadioGroup> */}
              </div>
              <div className={styles.loadedMilesInDays}>
                <MilesToDays miles={ldedMiles} />
              </div>
              <div className={styles.searchButtonContainer}>
                <Button
                  className={styles.searchButton}
                  text={'Go Fetch'}
                  disabled={!searchReady || loadingMilesAndExpected}
                  onClick={() => runSearch()} />
              </div>
              <div className={styles.expectedLabel}>Estimate</div>
              <div className={styles.expectedSearchInput}>
                {
                  (expected !== '') ?
                    <Popover
                      position={Position.TOP}
                      interactionKind={PopoverInteractionKind.HOVER_TARGET_ONLY}
                      hoverOpenDelay={100}
                      hoverCloseDelay={100}>
                        <ReadOnlyInput value={expected} />
                        <ExpectedMouseoverDetails confidence={confidenceMsg} />
                    </Popover>
                  :
                  <ReadOnlyInput value={expected} />
                }
              </div>
              <div className={styles.baselineLabel}>Base</div>
              <div className={styles.baselineSearchInput}>
                <ReadOnlyInput value={baseline} />
              </div>
              <div className={styles.quoteLabel}>Quote</div>
              <QuoteInputAndCalcButton
                value={quoteInputOverride}
                setQuoteInputOverride={setQuoteInputOverride}
                ref={quoteInputRef}
                baseline={baseline}
                ldedMiles={ldedMiles}
                fuelSurcharge={fuelSurcharge}
                marketRate={marketRate}
                marketRateLow={marketRateLow}
                marketRateHigh={marketRateHigh}
                loadingMilesAndExpected={loadingMilesAndExpected} />
              <div className={styles.scoreLabel}>Score</div>
              <div className={styles.scoreSearchInput}>
                <ScoreInput
                  expected={baseline}
                  quote={quoteInputOverride} />
              </div>
            </>
        }  
      </FetchSearchFormMilesAndExpected>
    </motion.div>
  )
})

export default ManualLoadSearch
