import React, { useContext } from 'react'
import styles from 'css/modules/ActiveMarketButton.module.css'
import { LoadFilterContext } from 'contexts/LoadFilterContext'

const ActiveMarketButton = ({
  pod,
  maxScore,
  toggleLoadsView,
  onClick,
  loadPodField
}) => {
  const { addFilter } = useContext(LoadFilterContext)
  const nbsp = '\u00A0'

  // _onClick used unless overwritten by passed in onClick prop
  const _onClick = () => {
    addFilter(loadPodField, { name: pod }) // loadPodField will either be origin_low_pod or destination_low_pod
    toggleLoadsView()
  }

  const scoreColorClass = (() => {
    if (maxScore === null) {
      return null
    }
    if (maxScore <= -100) {
      return styles.negativeScore
    } else if (maxScore >= 100) {
      return styles.positiveScore
    }
    return null
  })()

  return (
    <div className={styles.mainContainer} onClick={(onClick || _onClick)}>
      <div>
        {pod}
      </div>  
      <div className={[scoreColorClass, styles.scoreContainer].join(' ')}>
        { maxScore && (maxScore < 0 ? `-$${Math.abs(maxScore)}` : `+$${maxScore}`) }
        { !maxScore && nbsp } 
      </div>
    </div>
  )
}

export default ActiveMarketButton
