import React from 'react'
import moment from 'moment'

const UserHits = ({events, eventType, startDate, endDate}) => {
  let filteredEvents = events.filter(event => (event.event_type === 'search'))
  filteredEvents = filteredEvents.filter((event) => {
    let createdAt = moment(event.created_at).utc().local()
    return (createdAt.isAfter(startDate) && createdAt.isBefore(endDate))
  })
  return (
    <div>
      {filteredEvents.length > 0 && filteredEvents.length }
    </div>
  )
}

export default UserHits