import React, { useContext, useState, useEffect } from 'react'
import moment from 'moment'
import _ from 'lodash'
import { AppStyleContext } from 'contexts/AppStyleContext'
import { Button } from '@blueprintjs/core'
import { LoadFilterContext } from 'contexts/LoadFilterContext'
import { LoadSearchContext } from 'contexts/LoadSearchContext'
import styles from 'css/modules/PickupDateFilterControl.module.css'

const PickupDateFilterControl = ({
  loads,
  activeMarkets,
  setWhatsBestInfo,
  routeLoadsNoResults,
  setRouteLoadsNoResults}) => {

    const { appStyle } = useContext(AppStyleContext)
    const { addFilter, tryFilterBy, getAllFilters } = useContext(LoadFilterContext)
    const { search, hasRouteSearch, hasDestOnlySearch } = useContext(LoadSearchContext)
    const destOnly = hasDestOnlySearch()

    const [selectedButton, setSelectedButton] = useState(null)

    const loadPodField = destOnly ? 'origin_low_pod' : 'destination_low_pod'
    const getFilteredLoadPodName = (l) => {
      return destOnly ? l.origin_low_pod.name : l.destination_low_pod.name
    }
    
    // !!! allFilters is not used anywhere except for useEffect!
    // Small trick to make sure Pickup date filters update
    // when any filters are added or removed... recalculate what's best
    const allFilters = getAllFilters()

    let searchDates = []
    let MAX_DAYS_OUT = 5
    let searchDate = moment(search.dateStart)
    
    for (let daysOut = 0; daysOut < MAX_DAYS_OUT; daysOut++) {
      searchDates.push(searchDate.clone().add(daysOut, 'days').format('YYYY-MM-DD'))
    }

    // activePods is a list of all active pods
    let activePods

    if (destOnly) {
      activePods = activeMarkets.map(m => m.pod)
    } else {
      activePods = _.map(_.filter(activeMarkets, { active: true }), m => m.pod )
    }

    loads = tryFilterBy(loads, 'rate')

    // Filter Loads for Route Based lookup
    let routeLoadsNoResult;
    if(hasRouteSearch()) {
      let tmpLoads = _.filter(loads, function(obj) { return obj.destination_low_pod.name === search.destPod; });
      
      if(tmpLoads.length > 0) {
        loads = tmpLoads;
        routeLoadsNoResult = false; // State updated in useEffect
      } else {
        // Route-Based and we're falling back to Origin Only, Setting BestLoad to null
        routeLoadsNoResult = true; // State updated in useEffect
      }
    } else {
      routeLoadsNoResult = false; // Default Value
    }

    // Filter loads for this control by Active Markets
    if(!hasRouteSearch() || routeLoadsNoResults) { // Only if It's not a route Search, or the Route Search has rolled back to Origin Search
      loads = _.filter(loads, l => activePods.includes(getFilteredLoadPodName(l)))
    }

    let loadDatePartitions = {}
    searchDates.forEach(dateString => {
      let loadsPartition = _.filter(loads, { pickup_date: dateString })
      let twScoredOffers = _.map(loadsPartition.filter(load => load.tw_final_score !== null), 'tw_final_score')
      let expectedScores = _.map(loadsPartition.filter(load => load.tw_final_score === null && load.expected_score !== null), 'expected_score')
      let maxScore = Math.max(...(twScoredOffers.concat(expectedScores)))
      
      let bestLoad = _.maxBy(loadsPartition, l => {
        if (l.tw_final_score !== null) return l.tw_final_score
        if (l.expected_score !== null) return l.expected_score
        return null
      })

      
      if (bestLoad) {
        bestLoad = {
          score: bestLoad.tw_final_score || bestLoad.expected_score,
          pod: getFilteredLoadPodName(bestLoad),
          toOrFromPod: 'from'
        }
      }

      let count = loadsPartition.length
      loadDatePartitions[dateString] = {}
      loadDatePartitions[dateString].loads = loadsPartition
      loadDatePartitions[dateString].maxScore = maxScore
      loadDatePartitions[dateString].count = count
      loadDatePartitions[dateString].date = dateString
      loadDatePartitions[dateString].bestLoad = bestLoad
    })

    // Update the routeLoadsNoResults state here
    useEffect(() => {
      setRouteLoadsNoResults(routeLoadsNoResult);
    }, [routeLoadsNoResult])

    // Auto Select earliest pickup day filter on component mount
    useEffect(() => {
      if (selectedButton === null && loads.length >= 0) {
        for (let dateIndex = 0; dateIndex < searchDates.length; dateIndex++) {
          if (loadDatePartitions[searchDates[dateIndex]].count > 0) {
            setSelectedButton(searchDates[dateIndex])
            addFilter('pickup_date', searchDates[dateIndex])
            const loadPartition = loadDatePartitions[searchDates[dateIndex]]
            if (loadPartition.bestLoad) {
              setWhatsBestInfo(loadPartition.bestLoad)
            }
            break
          }
        }
      } else if (selectedButton) {
        // selectedButton is a string date '2021-01-01'
        try {
          // What's best changes on the date picked, calculated in this component, but set in parent
          setWhatsBestInfo(loadDatePartitions[selectedButton].bestLoad)
        } catch {
          setWhatsBestInfo(null)
        }
      }
    }, [activeMarkets, allFilters]) // update What's Best when Active Markets change

    const DateButton = ({partition}) => {
      let displayedDate
      let buttonDate
      if (partition.date !== 'ANY') {
        let partitionDate = moment(partition.date)
        let dateToday = moment()
        let dateTomorrow = dateToday.clone().add(1, 'days')
        let dateWeekOut = dateToday.clone().add(6, 'days')
        buttonDate = partitionDate.format('YYYY-MM-DD')
        displayedDate = partitionDate.format('MM/DD')
        if (partitionDate.isBefore(dateWeekOut)) {
          displayedDate = partitionDate.format('ddd')
        }
        
      } else {
        displayedDate = 'ANY'
        buttonDate = 'ANY'
      }

      let scoreColor
      let formattedScore
      if(isFinite(partition.maxScore)) {
        if (partition.maxScore <= -100) {
          scoreColor = appStyle.color.highlight.red
          formattedScore = `-$${Math.abs(partition.maxScore)}`
        } else if (partition.maxScore >= 100) {
          scoreColor = appStyle.color.highlight.green
          formattedScore = `+$${Math.abs(partition.maxScore)}`
        } else if (partition.maxScore > -100 && partition.maxScore < 100) {
          scoreColor = appStyle.color.highlight.yellow
          formattedScore = `${partition.maxScore >= 0 ? '+': '-'}$${Math.abs(partition.maxScore)}`
        }
      }

      return (
        <div style={{
          display: 'flex',
          flexDirection: 'column'
        }}>
          <Button
            className={selectedButton === buttonDate ? styles.dateButtonSelected : styles.dateButton}
            onClick={()=> {
              setSelectedButton(buttonDate)
              addFilter('pickup_date', partition.date)
              setWhatsBestInfo(partition.bestLoad)
            }}
            disabled={partition.loads.length === 0}>
            { displayedDate }
          </Button>
          <div style={{
            textAlign: 'center',
            color: scoreColor
          }}>
            { formattedScore }
          </div>
          <div style={{
            textAlign: 'center',
            color: appStyle.color.textLighter,
            fontSize: appStyle.text.small
          }}>
            { partition.count == 0 ? '' : partition.count }
          </div>
        </div>
      )
    }

    const FormLabel = ({value}) => {
      return (
        <div style={{
          paddingRight: 5,
          paddingLeft: 5,
          fontWeight: 'bold',
          fontSize: 12
        }}>
          {value}
        </div>
      )
    }
    
    return (
      <div>
        { /** <div className={styles.loadSourceContainer}>
          <div>
            <FormLabel value={'Universe:'} />
          </div>
          <div style={{
            fontSize: 12
          }}>
            InfiniteWisdom
          </div>
        </div> */ }
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          paddingBottom: 3
        }}>
          <div style={{
            display: 'flex',
            maxWidth: 400,
            justifyContent: 'space-around'
          }}>
            { 
              searchDates.map(date => {
                let partition = loadDatePartitions[date]
                return <DateButton key={date} partition={partition} />
              })
            }
          </div>
        </div>
      </div>
    )
}

export default PickupDateFilterControl