import React from 'react'
import { Spinner, Intent } from '@blueprintjs/core'
import styles from 'css/modules/LoadingMessage.module.css'

const LoadingMessage = ({
  useSpinner,
  message,
  spinnerPct, // 0.0 to 1.0
  renderImg,  // Any image to render inside spinner
  renderExtra // Any extra controls/components to render (optional)
}) => {
  const Image = () => {
    return renderImg ? renderImg() : null
  }

  const Extra = () => {
    return renderExtra ? renderExtra() : null
  }

  const SPINNER_SIZE = 100 // px

  return (
    <div className={styles.mainContainer}>
      <div className={styles.spinnerContainer}>
        { useSpinner &&
          <Spinner 
            value={spinnerPct}
            intent={Intent.PRIMARY}
            size={SPINNER_SIZE}/>
        }
      </div>
      <div className={styles.loadingMessageText}>
        { message }
      </div>
      { renderImg && 
        <div className={styles.imageContainer}>
          <Image />
        </div>
      }
      { renderExtra &&
        <div classname={styles.extraInfoContainer}>
          <Extra />
        </div>
      }
    </div>
  )
}

export default LoadingMessage