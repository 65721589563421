import React, { useContext } from 'react'
import { AppStyleContext } from 'contexts/AppStyleContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Auth from 'utils/Auth'
import styles from 'css/modules/AppHeader.module.css'
import LoadBuddyLogo from 'images/load_buddy_logo.svg'

const AppHeader = () => {
  const { appStyle } = useContext(AppStyleContext)
  if (!Auth.getInstance().getToken()) {
    return <div></div>
  }
  const { username, carrier } = Auth.getInstance().getLoginInfo()
  let carrierCode = null
  return (
    <div className={styles.mainContainer}>
      <div className={styles.headerItemsContainer}>
        <div className={styles.logoContainer}>
          <div className={styles.loadBuddyTextContainer}>
            <img className={styles.loadBuddyLogo} src={LoadBuddyLogo} />
          </div>
        </div>
        <div style={{
          alignSelf: 'center',
          fontSize: appStyle.text.large
        }}>
          { carrier }
        </div>
        <div
        style={{
          alignSelf: 'center',
          fontSize: appStyle.text.regular,
          color: appStyle.color.textLighter
        }}>
          <div style={{
            display: 'flex',
            flexWrap: 'nowrap',
            alignItems: 'flex-start'
          }}>
            <div>
              <FontAwesomeIcon icon='user' style={{marginRight: 5}}></FontAwesomeIcon>
            </div>
            <div>
              { username }
            </div>
          </div>
        </div>
          <div
          onClick={() => Auth.getInstance().logOut(true)}
          style={{
            alignSelf: 'center',
            fontSize: appStyle.text.regular,
            cursor: 'pointer',
            color: appStyle.color.textLighter
          }}>
            { username !== 'demo' ? 'Logout' : 'Exit' }
          </div>
      </div>
    </div>
  );
}

export default AppHeader

