import React from 'react'
import styles from 'css/modules/MouseoverPopupDetails.module.css'

const MouseoverPopupDetails = ({details}) => {
  return (
    <div className={styles.mainContainer}>
      <div>{details}</div>
    </div>
  )
}

export default MouseoverPopupDetails