import React from 'react'
import { FormGroup, Button, Menu, MenuItem, Popover, Position } from '@blueprintjs/core'
const LohPicker = ({ minLOH, setMinLOH }) => {
  return (
  <FormGroup
    label={''}>
      <Popover 
        position={Position.BOTTOM}
        content={
        <Menu>
          {
            (() => {
              let lohMenuList = []
              lohMenuList.push(<MenuItem key={'Any'} text={'Any'} onClick={() => setMinLOH(0)} />)
              for (let minLoh = 200; minLoh <= 1100; minLoh += 300) {
                lohMenuList.push(<MenuItem key={`> ${minLoh} mi`} text={`> ${minLoh} mi`} onClick={() => setMinLOH(minLoh)} />)
              }
              return lohMenuList
            })()
          }
        </Menu>
      }>
        <Button text={`${minLOH === 0 ? 'Any' : `>${minLOH}`}`} style={{minWidth: 60, textAlign: 'center'}} />
      </Popover>
  </FormGroup>
  )
}

export default LohPicker
