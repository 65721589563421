import _ from 'lodash'
/**
 * Contains helper methods for collections of users returned from the API
 */
const AdminUtil = (() => {

  const cleanupData = (data) => {
    return data.users.map(user => ({
      username: user.username,
      carrier: user.carrier.code,
      last_active: user.refresh_token.last_active || "",
      user_events: user.user_events
    }))
  }
  const sortByLastActive = (data) => {
    return _.orderBy(cleanupData(data), ['last_active'], ['desc'])
  }
  const sortByCarriers = (data) => {
    console.log('sorting')
    return _.orderBy(cleanupData(data), ['carrier', 'last_active', 'username'], ['asc', 'desc', 'asc'])
  }
  return (
    {
      sortByLastActive: sortByLastActive,
      sortByCarriers: sortByCarriers
    }
  )
})

export default AdminUtil