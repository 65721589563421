export const QueryParamTruckLocation = () => {
    const queryString = require('query-string')
    const parsed = queryString.parse(window.location.search)
    const cityStRegex = /^[A-Za-z\s]+,\s*[A-Za-z]{2}$/
    let queryParamTruckLocation
    let queryParamDest
    if(parsed.emty || parsed.EMTY) {
        let emty = parsed.emty || parsed.EMTY
        //console.log(emty.match(cityStRegex) !== null)
        if (emty.match(cityStRegex) !== null) {
        queryParamTruckLocation = emty
        }
    }

    if (!queryParamTruckLocation && (parsed.orig || parsed.ORIG)) {
        let orig = (parsed.orig || parsed.ORIG)
        if (orig.match(cityStRegex)) {
        queryParamTruckLocation = orig
        }
    }

    if (parsed.dest || parsed.DEST) {
        let dest = (parsed.dest || parsed.DEST)
        if(dest.match(cityStRegex)) {
        queryParamDest = dest
        }
    }

    // hacky way to clear the url search and GUID params after page load -- should probably change this later
    // let domainWithoutQueryParams = window.location.href.split('?')[0]
    // update the URL search bar without reloading the page
    // window.history.pushState({}, null, domainWithoutQueryParams);

    return { "TruckLocation": queryParamTruckLocation, 
                "TruckDestination": queryParamDest };
}

export const QueryParamGUID  = () => {
    
    const queryString = require('query-string')
    const parsed = queryString.parse(window.location.search)
    let autoLoginGuid = null

    if(parsed.guid || parsed.GUID) {
        autoLoginGuid = parsed.guid || parsed.GUID;
    }

    // hacky way to clear the url search and GUID params after page load -- should probably change this later
    // let domainWithoutQueryParams = window.location.href.split('?')[0]
    // update the URL search bar without reloading the page
    // window.history.pushState({}, null, domainWithoutQueryParams);

    return autoLoginGuid;
}