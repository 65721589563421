import React, { useState, useEffect, useContext } from 'react'
import { LoadSearchContext } from 'contexts/LoadSearchContext'
import { TWWebServicesContext } from 'contexts/TWWebServicesContext'
import UserEvent from 'utils/UserEvent'
import LoadingMessage from 'components/loads/LoadingMessage'
import PlanetOrbit from 'images/planet_purple_orbit.svg'

const ScoreLoads = (props) => {
  const { loads } = props
  const [scoredLoads, setScoredLoads] = useState(null)
  const [loading, setLoading] = useState(true)
  const [loadingScoreStats, setLoadingScoreStats] = useState({
    scoredCount: 0,
    totalCount: 0
  })
  const { scoredCount, totalCount } = loadingScoreStats
  const { search } = useContext(LoadSearchContext)
  const { twScoreLoads } = useContext(TWWebServicesContext)
  const { truck, study, division, resolution, truckEquipment, } = search
  const updateLoadsScoringStatus = (scoredCount, totalCount) => {
    return (scoredCount, totalCount) => {
      setLoadingScoreStats({ scoredCount, totalCount })
    }
  }

  useEffect(() => {
    if (loading) {
      twScoreLoads(truck, loads, division, study, resolution, truckEquipment,
        updateLoadsScoringStatus(loads.length)).then(scoredLoads => {
          setScoredLoads(scoredLoads)
          setLoading(false)
          // Search Tracking -- not end of world if this doesn't go through
          try {
            UserEvent().postSearchEvent({
              search: search,
              loads: scoredLoads
            })
          } catch {  }
      })
    }
  }, [])

  const loadingMessage = `Scoring ${scoredCount} Loads`

  if (loading) {
    return (
      <LoadingMessage
        useSpinner={true}
        spinnerPct={scoredCount/(totalCount+1)}
        message={loadingMessage}
        renderImg={() =>
          <img src={PlanetOrbit} />
        }/>
    )
  }

  return (
    <>
      {
        props.children({ loads: scoredLoads })
      }
    </>
  )
}

export default ScoreLoads