import React, { useState, useEffect, useContext } from 'react'
import Auth from 'utils/Auth'
import { SettingsContext } from 'contexts/SettingsContext'
import _ from 'lodash'

const FetchActiveMarkets = (props) => {
  const { settings } = useContext(SettingsContext)
  const [activeMarkets, setActiveMarkets] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  const cleanupActiveMarkets = (mrkts) => {
    return mrkts.map(mrkt => {
      let { pod, obs, activemarket } = mrkt
      return {
        pod,
        obs: parseInt(obs),
        active: activemarket === "True"
      }
    })
  }

  useEffect(() => {
    if (loading) {
      fetch(`${settings.AUTH_URL}/active_markets`, {
        headers: {
          Authorization: Auth.getInstance().getToken()
        }
      })
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          throw Error('Error Fetching Active Markets')
        }
      })
      .then(data => {
        setLoading(false)
        setActiveMarkets(cleanupActiveMarkets(data))
      })
      .catch(error => {
        setError(true)
        setLoading(false)
      })
    } else if (error) {
      setError(true)
    }
  }, [])

  if (error || loading || activeMarkets === null) {
    return <></>
  }

  return (
    <>
      {
        props.children({ activeMarkets })
      }
    </>
  )
}

export default FetchActiveMarkets