import React, { useState, useContext } from 'react'
import styles from 'css/modules/ShowingDialogControl.module.css'
import { LoadFilterContext } from 'contexts/LoadFilterContext'
import { LoadSearchContext } from 'contexts/LoadSearchContext'

const ShowingDialogControl = ({ showAllMarkets, showOnlyActiveMarkets }) => {
  const QUOTES_ONLY_TEXT = 'loads with quotes'
  const ALL_LOADS_TEXT = 'all loads'
  const ACTIVE_MARKET_TEXT = 'active markets'
  const ALL_MARKET_TEXT = 'all markets'
  
  const { search, setSearch, hasDestOnlySearch } = useContext(LoadSearchContext)
  const destOnly = hasDestOnlySearch()
  const { truck, quotesOnly } = search
  const [dropdownQuotesOnly, setDropdownQuotesOnly] = useState(quotesOnly)
  const [activeMarketOnly, setActiveMarketOnly] = useState(destOnly ? false : true)

  // After all loads have been fetched, moving back to quotes only just a filter
  // (No refetch required)
  const { addFilter, hasFilter } = useContext(LoadFilterContext)
  
  const runNewSearch = (_quotesOnly) => {
    setSearch(truck, {
      ...search,
      quotesOnly: _quotesOnly
    })
  }

  const onQuoteChange = (e) => {
    switch(e.target.value) {
      case QUOTES_ONLY_TEXT:
        if (!dropdownQuotesOnly) {
          addFilter('rate', l => l.rate > 0)
          setDropdownQuotesOnly(true)
        }
        break
      case ALL_LOADS_TEXT:
        if (dropdownQuotesOnly) {
          if (hasFilter('rate')) {
            addFilter('rate', l => l.rate > -1) // empty rates are actually 0, not obvious!
            setDropdownQuotesOnly(false)
          } else {
            runNewSearch(false)
          }
        }
        break
    }
  }

  const onMarketChange = (e) => {
    switch(e.target.value) {
      case ACTIVE_MARKET_TEXT:
        if (!activeMarketOnly) {
          setActiveMarketOnly(true)
          showOnlyActiveMarkets()
        }
        break
      case ALL_MARKET_TEXT:
        if (activeMarketOnly) {
          setActiveMarketOnly(false)
          showAllMarkets()
        }
        break
    }
  }

  return (
    <div className={styles.mainContainer}>
      <div>Viewing</div>
      <div className={styles.leftRightPadding}>
        <select
          value={dropdownQuotesOnly ? QUOTES_ONLY_TEXT : ALL_LOADS_TEXT}
          onChange={onQuoteChange}>
          <option key={QUOTES_ONLY_TEXT} value={QUOTES_ONLY_TEXT}>
              { QUOTES_ONLY_TEXT }
          </option> 
          <option key={ALL_LOADS_TEXT} value={ALL_LOADS_TEXT}>
              { ALL_LOADS_TEXT }
          </option>
        </select>
      </div>
      <div>
        { destOnly ? 'from' : 'to' }
      </div>
      <div className={styles.leftRightPadding}>
        <select
          value={activeMarketOnly ? ACTIVE_MARKET_TEXT : ALL_MARKET_TEXT}
          onChange={onMarketChange}>
          <option key={ACTIVE_MARKET_TEXT} value={ACTIVE_MARKET_TEXT}>
              { ACTIVE_MARKET_TEXT }
          </option> 
          <option key={ALL_MARKET_TEXT} value={ALL_MARKET_TEXT}>
              { ALL_MARKET_TEXT }
          </option>
        </select>
      </div>
      
    </div>
  )
}

export default ShowingDialogControl
