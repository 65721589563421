import React from 'react'
import { BrowserRouter, Route } from "react-router-dom"
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faEnvelope,
  faPhone,
  faCogs,
  faCheck,
  faSearch,
  faUser,
  faArrowDown,
  faRobot,
  faAngleDoubleDown,
  faClock,
  faCog,
  faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { SettingsProvider } from './contexts/SettingsContext'
import { AppStyleProvider } from './contexts/AppStyleContext'
import { TrucksProvider } from './contexts/TrucksContext'
import { TWWebServicesProvider } from './contexts/TWWebServicesContext'
import { LoadSearchProvider } from './contexts/LoadSearchContext'
import styles from './css/modules/App.module.css'
import { isIE } from 'react-device-detect'
import BaseRoute from './routes/BaseRoute'
import AdminRoute from './routes/AdminRoute'

library.add(
  faCheck,
  faCog,
  faCogs,
  faSearch,
  faUser,
  faArrowDown,
  faAngleDoubleDown,
  faRobot,
  faClock,
  faEnvelope,
  faPhone,
  faArrowUp)

const App = () => {

  if (isIE) {
    return (
      <div className={styles.ieErrorLayout}>
        <p>LoadBuddy.AI is not compatible with Internet Explorer at this time.</p>
        <p>Please use a modern browser such as Chrome or Firefox.</p>
      </div>
    )
  }

  const BaseRouteContextList = (props) => {
    return (
      <SettingsProvider>
        <TWWebServicesProvider>
          <TrucksProvider>
            <LoadSearchProvider>
              <AppStyleProvider>
                { props.children }
              </AppStyleProvider>
            </LoadSearchProvider>
          </TrucksProvider>
        </TWWebServicesProvider>
      </SettingsProvider>
    )
  }

  const AdminRouteContextList = (props) => {
    return (
      <SettingsProvider>
        <AppStyleProvider>
          { props.children }
        </AppStyleProvider>
      </SettingsProvider>
    )
  }


  return (
    <BrowserRouter>
      <Route exact path="/">
        <BaseRouteContextList><BaseRoute /></BaseRouteContextList>
      </Route>
      <Route path="/admin">
        <AdminRouteContextList><AdminRoute /></AdminRouteContextList>
      </Route>
    </BrowserRouter>
  )
}

export default App
