import React, {useState, useEffect, useContext} from 'react'
import { AppStyleContext } from 'contexts/AppStyleContext'
import styles from 'css/modules/DeliveryDays.module.css'
import moment from 'moment'

const DeliveryDays = ({startDate, expectedDays, score, setDisplayedScore}) => {
  const { appStyle } = useContext(AppStyleContext)
  const [deliveryDays, setDeliveryDays] = useState([])
  const [selectedDay, setSelectedDay] = useState(null)
  let MAX_DAYS_OUT = 5
  // Edge case when expected days are >= 5
  let dayAdjustment = 0
  let dayDifference = 2 - (Math.round(MAX_DAYS_OUT - expectedDays))
  if (dayDifference > 0) {
    dayAdjustment = dayDifference
    MAX_DAYS_OUT += dayAdjustment
  }
  let DOLLARS_PER_DAY = 500
  let EXPD_DAY_ADJUSTMENT = 1.2 // 1.2 days per actual day of 500 miles
  
  useEffect(() => {
    let _deliveryDays = []
    let initialSelectedDay = null
    let sundayFound = false 
    for (let daysOut = 1 + dayAdjustment; daysOut <= MAX_DAYS_OUT; daysOut++) {
      let day = moment(startDate).add(daysOut, 'days').format('ddd')
      if (!sundayFound) {
        sundayFound = (day === 'Sun')
      }
      let actualDaysOut = daysOut
      if (sundayFound) {
        actualDaysOut -= 1
      }
      let dayObj = {
        day: day,
        value: score ? score + (DOLLARS_PER_DAY * (Math.ceil(expectedDays/EXPD_DAY_ADJUSTMENT) - actualDaysOut )) : score,
        expectedDay: daysOut == Math.ceil(expectedDays/EXPD_DAY_ADJUSTMENT),
        showValue: score !== null
      }
      if(dayObj.expectedDay && !selectedDay) {
        initialSelectedDay = dayObj.day
        setDisplayedScore(dayObj.value)
      } else if (selectedDay === dayObj.day) {
        setDisplayedScore(dayObj.value)
      }
      _deliveryDays.push(dayObj)
    }
    setDeliveryDays(_deliveryDays)
    if(!selectedDay) {
      setSelectedDay(initialSelectedDay)
    }
  }, [score])
  
  
  const LabeledValue = ({label, value, valueColor, expectedDay, showValue}) => {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column'
      }}>
        <div
          style={{
            width: '100%',
            color: (label === selectedDay) || expectedDay ? 'white' : appStyle.color.textLighter,
            paddingLeft: 10,
            paddingRight: 10,
            textAlign: 'center',
            fontWeight: expectedDay && 'bold',
            fontSize: appStyle.text.small,
            backgroundColor: label === selectedDay && '#9c4a93',
            borderRadius: label === selectedDay && 5
          }}
          className={styles.day}
          onClick={()=>{
            setSelectedDay(label)
            setDisplayedScore(value)
            }}>
            {label}
        </div>
      </div>
    )
  }

  return (
    <div>
      <div style={{fontSize: appStyle.text.small, color: appStyle.color.textLighter}}>
          Delivers
      </div>
      <div style={{
        display: 'flex',
        justifyContent: 'space-around'
      }}>
        { deliveryDays.map((day) => 
          <LabeledValue
            key={JSON.stringify(day)}
            showValue={day.showValue}
            valueColor={day.value < 0 ? appStyle.color.highlight.red : appStyle.color.highlight.green}
            label={day.day} value={day.value}
            expectedDay={day.expectedDay} 
          />) }
      </div>
    </div>
  )
}

export default DeliveryDays