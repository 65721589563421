import React, { Fragment, useEffect } from 'react'
import StarRating from 'components/shared/StarRating'

const ScoreStarRating = ({score}) => {

    const numStars = (score) => {
      let starCount = 0
      let starValueIncrement = 200
      let max_stars = StarRating.MAX_STARS;
      score = score || 0; // Handles null

      if (score > 0) {
        starCount = Math.floor(score/starValueIncrement);
        if (starCount > max_stars) {
          starCount = max_stars;
        }
      }
      
      return starCount
    }

    return (
        <StarRating stars={numStars(score)}></StarRating>
    )

}

export default ScoreStarRating;