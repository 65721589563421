import React, { useState, useContext, useEffect } from 'react'
import { LoadSearchContext } from 'contexts/LoadSearchContext'
import { cleanupLoads, createLoadsQuery } from 'utils/LoadUtil'
import { useLazyQuery } from '@apollo/react-hooks'
import LoadingMessage from 'components/loads/LoadingMessage'
import PlanetOrbit from 'images/planet_purple_orbit.svg'
import _ from 'lodash'

const FetchLoads = (props) => {
  const { search, searchEmpty } = useContext(LoadSearchContext)
  const [previousSearch, setPreviousSearch] = useState(null)
  const hasNewSearch = !(_.isEqual(search, previousSearch))
  
  const [fetchLoads, { loading, error, data }] = useLazyQuery(
    createLoadsQuery(search), {
      fetchPolicy: 'network-only'
    }
  )

  useEffect(() => {
    if (!searchEmpty() && hasNewSearch && !loading) {
      setPreviousSearch(search)
      fetchLoads()
      return
    }
  }, [search])

  if (error) {
    // errored out! refresh the page... Probably due to timed out log in
    let domainWithoutQueryParams = window.location.href.split('?')[0]
    window.location.replace(domainWithoutQueryParams)
  }

  if (loading && !searchEmpty()) {
    return (
      <LoadingMessage
        useSpinner={true}
        message={'Searching the Universe'}
        renderImg={() =>
          <img src={PlanetOrbit} />
        } />
    )
  }

  // show nothing when no data or the search is empty (changed)
  if (!data || searchEmpty()) {
    return (<></>)
  }

  // Perform cleanup of loads before putting through rest of app (duplicates)
  const loads = cleanupLoads(data.loadboard_loads)

  return (
    <>
      {
        props.children({ loads })
      }
    </>
  )
}

export default FetchLoads