import 'react-app-polyfill/ie11' // IE 11 support
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'
import './css/App.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './fonts/RobotoMono-Regular.ttf'
import './fonts/RobotoMono-Bold.ttf'
import './fonts/kimberley-bl.ttf'

import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css'

ReactDOM.render( <App /> , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
