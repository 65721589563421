import React, { useContext } from 'react'
import { AppStyleContext } from 'contexts/AppStyleContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AdminAuth from 'utils/admin/AdminAuth'
import styles from 'css/modules/AdminAppHeader.module.css'
import LoadBuddyLogo from 'images/load_buddy_logo.svg'

const AdminAppHeader = () => {
  const { appStyle } = useContext(AppStyleContext)
  if (!AdminAuth.getInstance().getToken()) {
    return <div></div>
  }
  const { username } = AdminAuth.getInstance().getLoginInfo()
  let carrierCode = null
  return (
    <div className={styles.mainContainer}>
      <div className={styles.headerItemsContainer}>
        <div className={styles.logoContainer}>
          <div className={styles.loadBuddyTextContainer}>
            <img className={styles.loadBuddyLogo} src={LoadBuddyLogo} />
          </div>
        </div>
        <div style={{
          alignSelf: 'center',
          fontSize: appStyle.text.large
        }}>
          { "ADMIN" }
        </div>
        <div
        style={{
          alignSelf: 'center',
          fontSize: appStyle.text.regular,
          color: appStyle.color.textLighter
        }}>
          <div style={{
            display: 'flex',
            flexWrap: 'nowrap',
            alignItems: 'flex-start'
          }}>
            <div>
              <FontAwesomeIcon icon='user' style={{marginRight: 5}}></FontAwesomeIcon>
            </div>
            <div>
              { username }
            </div>
          </div>
        </div>
          <div
          onClick={() => AdminAuth.getInstance().logOut(true)}
          style={{
            alignSelf: 'center',
            fontSize: appStyle.text.regular,
            cursor: 'pointer',
            color: appStyle.color.textLighter
          }}>
            { "Logout" }
          </div>
      </div>
    </div>
  );
}

export default AdminAppHeader

