import React, { useState, useContext } from 'react'
import _ from 'lodash'
import { LoadSearchContext } from 'contexts/LoadSearchContext'

// Contains stateful logic for Filtering the pods view
// User can set observation threshold to define amount of activity
// required to define an active market.
const FilterActiveMarkets = (props) => {
  const { activeMarkets } = props

  // activeMarkets ==
  // [{
  //   pod: 'TX-DAL',
  //   obs: 12,
  //   active: true
  // },
  // {
  //   pod: 'AL-BIR',
  //   obs: 1,
  //   active: false
  // }, ...]

  const filterByActiveFlag = () => {
    return _.filter(activeMarkets, { active: true })
  }
  
  const { search, setSearch, hasDestOnlySearch } = useContext(LoadSearchContext)
  const destOnly = hasDestOnlySearch()

  const [activeMarketsByObsThreshold, setActiveMarketsByObsThreshold] = useState(destOnly ? activeMarkets : filterByActiveFlag())
  const [observationsThreshold, _setObservationsThreshold] = useState(destOnly ? 0 : null)

  const showAllMarkets = () => {
    setObservationsThreshold(0) // !! will populate every pod
  }

  const showOnlyActiveMarkets = () => {
    setActiveMarketsByObsThreshold(filterByActiveFlag())
  }

  // Sets the available pods to click on based on the observations being >= user defined threshold
  const setObservationsThreshold = (threshold) => {
    _setObservationsThreshold(threshold)
    setActiveMarketsByObsThreshold(
      _.filter(activeMarkets.map(mrkt => {
        let { pod, obs } = mrkt
        return {
          pod,
          obs,
          active: (obs >= threshold)
        }
      }),
      { active: true }
    ))
  }

  return (
    <>
      {
        props.children({
          activeMarkets: activeMarketsByObsThreshold,
          showAllMarkets,
          showOnlyActiveMarkets,
          observationsThreshold,
          setObservationsThreshold
        })
      }
    </>
  )
}

export default FilterActiveMarkets
