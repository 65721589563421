import React, { useContext, useEffect, useState, Fragment } from 'react'
import { AppStyleContext } from 'contexts/AppStyleContext'
import { SettingsContext } from 'contexts/SettingsContext'
import LabeledValue from './LabeledValue'
import styles from 'css/modules/LoadDetails.module.css'

const LoadDetails = ({load, twScore}) => {
  const { appStyle } = useContext(AppStyleContext)

  
  const { all_in_rate, contact, calculated_miles_estimated, calculated_practical_miles,
    calculated_short_miles, comment, complete, contact_id, created_at,
    date_start, delivery_date, destination_city, destination_state, distance,
    equipment, equipment_category, height, lane_id, length, load_count, market_percentage,
    origin_city, origin_state, post_type, rate, score, stops, tracking_number, weight } = load

  const { contact_name, comp_city, comp_state, phone } = contact
  
  return (
    <Fragment>
      <div className={styles.comments}>
          <div style={{
            color: !comment && appStyle.color.textLighter,
            fontSize: appStyle.text.small
          }}>
            {comment ? comment : '(No Comments)'}
          </div>
      </div>
      <div className={styles.equip}>
        <LabeledValue label={'Equip'} value={equipment_category} />
      </div>
      <div className={styles.weight}>
        <LabeledValue label={'Weight'} value={weight} />
      </div>
      <div className={styles._length}>
        <LabeledValue label={'Length'} value={length} />
      </div>
      <div className={styles.loadCount}>
        <LabeledValue label={
          <span>
            Count
          </span>} value={load_count} />
      </div>
      <div className={styles.stops}>
        <LabeledValue label={'Stops'} value={stops} />
      </div>
    </Fragment>
  )
}

export default LoadDetails