import React from 'react'
import { InputGroup } from '@blueprintjs/core'


const ReadOnlyInput = ({ value }) => {
  return <InputGroup
            value={value} 
            readOnly={true} />
}

export default ReadOnlyInput