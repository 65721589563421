import React from 'react'
import { FormGroup, InputGroup, Button, Menu, MenuItem, Popover, Position, Radio, RadioGroup, Checkbox } from '@blueprintjs/core'

const RangeMiles = ({
  origOrDest,
  originRange,
  destinationRange,
  cityST,
  destCityST,
  emptyCityST,
  setFetchPods,
  setFetchDestPods,
  setFetchEmptyPods,
  setOriginRange,
  setDestinationRange,
  minLOHRange,
  maxLOHRange
 }) => {
  // RangeMiles have 2 states, either part of the origin, or the destination state depending
  // on what's passed in
  const currentRange = origOrDest === 'origin' ? originRange : destinationRange

  const updateRange = (range) => {
    if(origOrDest === 'origin' && range !== originRange) {
      setOriginRange(range)
      // Range picker prioritizes empty over origin,
      // Search logic dictates use Empty if it's there, otherwize origin
      if (emptyCityST !== '') { 
        setFetchEmptyPods(true)
      }
      if (cityST !== '') {
        setFetchPods(true)
      }
      if (destCityST !== '') {
        setFetchDestPods(true)
      }
    } else if(origOrDest === 'destination' && range !== destinationRange){
      setDestinationRange(range)
    }
  }

  let menuItems = []
  for (let range = minLOHRange; range <= maxLOHRange; range+=100) {
    menuItems.push(<MenuItem key={`${origOrDest}${range}`} text={`~${range} mi`} onClick={() => updateRange(range)} />)
  }

  return (
    <FormGroup
      label={''}>
      <Popover 
        position={Position.BOTTOM}
        content={
          <Menu>
            { menuItems }
          </Menu>}>
          <Button text={`~${currentRange}`} />
        </Popover>
    </FormGroup>
  )
}

export default RangeMiles