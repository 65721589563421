import React, { useState, createContext } from 'react'

export const SettingsContext = createContext()

export const SettingsProvider = props => {
  const [settings, setSettings] = useState({
    AUTH_URL: `${process.env.REACT_APP_AUTH_URL}`,
    GRAPHQL_URL: `${process.env.REACT_APP_GRAPHQL_URL}`
  })
  return (
    <SettingsContext.Provider value={{settings, setSettings}}>
      { props.children }
    </SettingsContext.Provider>
  )
}

