import React, { useContext } from 'react'
import { LoadFilterContext } from 'contexts/LoadFilterContext'
import _ from 'lodash'

const FilterLoads = (props) => {
  const { loads } = props
  const { filter } = useContext(LoadFilterContext)
  const filteredLoads = filter(loads)
  
  return (
    <>
      {
        props.children({ loads, filteredLoads })
      }
    </>
  )
}

export default FilterLoads
