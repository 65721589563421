import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import styles from 'css/modules/TimeAgo.module.css'

const TimeAgo = ({timestamp}) => {
  const [message, setMessage] = useState(moment.utc(timestamp).local().fromNow())
  useEffect(() => {
    let timeUpdatorInterval = setInterval(() => {
      setMessage(moment.utc(timestamp).local().fromNow())
    }, 60000)
    return (() => {
      clearInterval(timeUpdatorInterval)
    })
  }, [])
  return (
    <div>
      <FontAwesomeIcon icon='clock' style={{marginRight: 5}}></FontAwesomeIcon>
      <span className={styles.timeAgoText}>
        {message}
      </span>
    </div>
  )
}

export default TimeAgo