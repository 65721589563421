import React, { useState, createContext } from 'react'

export const AppStyleContext = createContext()

export const AppStyleProvider = props => {
  const [appStyle, setAppStyle] = useState({
    text: {
      small: 12,
      regular: 14,
      large: 18,
      largest: 22
    },
    color: {
      text: '#FAFAFA',
      textLighter: '#CCCCCC',
      background: {
        main: '#001C44',
        accent1: '#F5F5F5',
        accent2: '#EAEBEB'
      },
      highlight: {
        blue: '#1F7CC1',
        lightBlue: '#F5F5FC',
        green: '#2CF985',
        red: '#F92C54',
        yellow: '#c39200',
        yellow: '#FAFAFA',
        mouseOver: '#FAFAD2'
      }
    },
    trucks: {
      table: {
        width: 520,
        padding: 10,
        column: {
          padding: 8,
          tractorWidth: 80,
          trailerWidth: 80,
          driverWidth: 80,
          equipmentWidth: 50,
          destinationWidth: 160,
          destinationCityWidth: 130,
          destinationStateWidth: 30,
          orderNumWidth: 60,
          ptaWidth: 100
        }
      }
    },
    loads: {
      table: {
        row: {
          width: 350
        },
        width: 365,
        padding: 10,
        column: {
          padding: 5,
          dateStartWidth: 75,
          equipmentWidth: 65,
          cityWidth: 120,
          stateWidth: 30,
          placeWidth: 160,
          rateWidth: 80,
          milesWidth: 90,
          companyWidth: 185
        }
      }
    }
  })
  return (
    <AppStyleContext.Provider value={{ appStyle, setAppStyle }}>
      { props.children }
    </AppStyleContext.Provider>
  )
}

