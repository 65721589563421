import React from 'react';

const LabeledValue = ({label, value}) => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column'
    }}>
      <div
        style={{
          width: '100%',
          textAlign: 'center'
        }}>
          {value}
      </div>
      <div
        style={{
          width: '100%',
          color: '#CCCCCC',
          textAlign: 'center',
          fontSize: 12
        }}>
          {label}
      </div>
    </div>
  )
}

export default LabeledValue