import React, { useRef, useEffect, useContext } from 'react'
import Load from './Load'
import { VariableSizeList as List } from 'react-window'
import { LoadSearchContext } from 'contexts/LoadSearchContext'
import AutoSizer from 'react-virtualized-auto-sizer'
import Jupiter from 'images/jupiter.svg'

const LoadList = ({loads,
  filteredLoads,
  loadingMessage,
  loadListContainerRef,
  manualSearchFormRef}) => {

    const LOAD_TOP_MARGIN = 30
    const UNOPENED_LOAD_ITEM_SIZE = 214 + LOAD_TOP_MARGIN // height + top margin
    const OPENED_LOAD_ITEM_SIZE = 308 + LOAD_TOP_MARGIN // height + top margin

    const { search, setSearch, hasDestOnlySearch } = useContext(LoadSearchContext)
    const destOnlySearch = hasDestOnlySearch()

    useEffect(() => {
      // resize rows on re-render (only if already rendered)
      if (loadListContainerRef && loadListContainerRef.current) {
        loadListContainerRef.current.resetAfterIndex(0, true)
      }
    }, [filteredLoads])

    if (loadingMessage) {
      return (
        <div style={{
          width: '100%',
          paddingTop: 30,
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}>
          <div>
            <img style={{height: 50, width: 'auto'}} src={Jupiter} />
          </div>
          <div style={{
            alignSelf: 'center',
            width: 200,
            paddingTop: 30 
          }}>
            {loadingMessage}
          </div>
        </div>
      )
    }

    if (loads === null) {
      return (
        <div></div>
      )
    }

    if (loads && loads.length === 0 && search.truck !== null && !loadingMessage) {
      return (
        <div style={{
          paddingTop: 30,
          textAlign: 'center'
        }}>No Loads Found</div>
      )
    }

    if (!filteredLoads || filteredLoads.length == 0) {
      return (
        <div></div>
      )
    }

    const rowHeights = new Array(filteredLoads.length).fill(true).map(() => UNOPENED_LOAD_ITEM_SIZE)
    // first row no top margin
    if(rowHeights.length > 0) {
      rowHeights[0] = UNOPENED_LOAD_ITEM_SIZE - LOAD_TOP_MARGIN
    }


    const openedRows = new Array(filteredLoads.length).fill(true).map(() => false)

    const getRowItemHeight = index => rowHeights[index]
    const setRowOpened = index => {
      rowHeights[index] = index === 0 ? OPENED_LOAD_ITEM_SIZE - LOAD_TOP_MARGIN : OPENED_LOAD_ITEM_SIZE
      openedRows[index] = true
      // Updates position of everything below in the list
      loadListContainerRef.current.resetAfterIndex(index, true)
    }


    const sortLoads = (loads) => {
      loads.sort((l1, l2) => {
        let l1Score = l1.tw_final_score
        if(!l1Score) {
          // if no TW score, use expected score
          l1Score = l1.expected_score
        }
        let l2Score = l2.tw_final_score
        if(!l2Score) {
          l2Score = l2.expected_score
        }
        if(l1Score && !l2Score) {
          return -1
        }
        if (!l1Score && l2Score) {
          return 1
        }
        if (!l1Score && !l2Score) {
          return 0
        }
        return l2Score - l1Score
      })
      return loads
    }

    const LoadListItem = ({ index, style, data }) => {
      return (
        <Load
          load={data[index]}
          destOnlySearch={destOnlySearch}
          style={style}
          index={index}
          key={JSON.stringify(data[index])}
          renderOpened={openedRows[index]}
          manualSearchFormRef={manualSearchFormRef}
          openResizeList={(index) => setRowOpened(index)} />
      )
    }

    const LoadListContainer = (props) => {
      return (<div {...props} />)
    }

    
    return (
      <AutoSizer
        style={{
          fontFamily: 'Roboto Mono',
          height: 'calc(100vh - 328px)',
      }}>
        {({ height, width }) => (
        <List
          ref={loadListContainerRef}
          height={height}
          width={width}
          itemCount={filteredLoads.length}
          itemSize={getRowItemHeight}
          itemData={sortLoads(filteredLoads)}
          innerElementType={LoadListContainer}>
            { LoadListItem }
          </List>
        )}
      </AutoSizer>
    )
}

export default LoadList