import gql from 'graphql-tag'
import _ from 'lodash'
import moment from 'moment'
const stringifyObject = require('stringify-object')
/**
 * Contains helper methods for collections of loads returned from the API
 */

export const createLoadsQuery = (search) => {
  const { dateStart, dateEnd, equipment, pods,
          destPods, quotesOnly, minLOH } = search
  const query = gql`
    {
      loadboard_loads(
        limit: 1000,
        order_by: {date_start: asc, rate: desc},
        where: {
          _and: [
            { _and: [
              {date_start: ${stringifyObject({_gte: dateStart}, {singleQuotes: false})}},
              {date_start: ${stringifyObject({_lt: dateEnd}, {singleQuotes: false})}}
            ] },
            ${stringifyObject(equipment, {singleQuotes: false})},
            {origin_pod_low_id: ${pods ? stringifyObject({_in: pods}, {singleQuotes: false}) : stringifyObject({_is_null: false})}},
            {full_load_size: ${stringifyObject({_eq: true})}},
            {rate: ${stringifyObject({_gt: quotesOnly ? 0 : -1})}},
            {stops: ${stringifyObject({_eq: 0})}},
            {calculated_practical_miles: ${stringifyObject({_gte: minLOH})}},
            {destination_pod_low_id: ${destPods ? stringifyObject({_in: destPods}, {singleQuotes: false}) : stringifyObject({_is_null: false})}}
          ] 
        }) {
        contact {
          comp_name
          shared_comp_name
          phone
          comp_city
          comp_state
          contact_name
        }
        origin_low_pod {
          name
        }
        destination_low_pod {
          name
        }
        all_in_rate
        calculated_miles_estimated
        calculated_practical_miles
        calculated_short_miles
        comment
        complete
        created_at
        updated_at
        date_start
        delivery_date
        destination_city
        destination_state
        distance
        equipment
        equipment_category
        height
        lane_id
        length
        load_count
        market_percentage
        origin_city
        origin_state
        post_type
        rate
        score
        stops
        weight
        tracking_number
      }
    }`
    // Uncomment to view output of query in console
    //console.log(query.loc.source.body)
    return query
}

const addExtraAttributes = (load) => {
  const pickupDateFormat = 'YYYY-MM-DD'
  return {
    ...load,
    pickup_date: moment(load.date_start).format(pickupDateFormat)
  }
}

export const cleanupLoads = (loads) => {
  let duplicateCriteriaProps = [
    'origin_city',
    'score',
    'origin_state',
    'date_start',
    'destination_city',
    'destination_state',
    'equipment',
    'post_type',
    'contact_id',
    'equipment_category',
    'stops',
    'load_count',
    'market_percentage',
    'full_load_size',
    'rate',
    'calculated_practical_miles',
    'calculated_short_miles',
    'complete',
    'calculated_miles_estimated',
    'lane_id'
  ]
  let cleanedLoads = _.uniqWith(loads, (l1, l2) => _.isEqual(_.pick(l1, duplicateCriteriaProps), _.pick(l2, duplicateCriteriaProps)))
  return cleanedLoads.map(addExtraAttributes)
}
