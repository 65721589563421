// Handler for route /
import React, { useContext, useEffect, useState, useRef } from 'react'
import ApolloClient from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloProvider } from '@apollo/react-hooks'
import { WebSocketLink } from 'apollo-link-ws'
import Login from 'components/Login'
import AppHeader from 'components/AppHeader'
import ManualLoadSearch from 'components/loads/search/ManualLoadSearch'
import Loads from 'components/loads/Loads'
import Auth from 'utils/Auth'
import { QueryParamTruckLocation, QueryParamGUID } from 'utils/QueryParamUtils'
import { AppStyleContext } from 'contexts/AppStyleContext'
import styles from 'css/modules/App.module.css'

const webSocketLink = new WebSocketLink({
    uri: `${process.env.REACT_APP_WEBSOCKET_URL}`,
    options: {
      reconnect: true,
      lazy: true,
      // connectionParams set to a function here so that every request uses the most recent
      // token returned from LB backend via autorefresh
      connectionParams: () => ({
        headers: {
          Authorization: Auth.getInstance().getToken()
        }
      })
    }
  })
  
  const createApolloClient = () => {
    return new ApolloClient({
      link: webSocketLink,
      cache: new InMemoryCache(),
   })
  }

const BaseRoute = () => {
 
    const { appStyle } = useContext(AppStyleContext)
    const [loggedIn, setLoggedIn] = useState(false)
    let authToken = Auth.getInstance().getToken()
    let TruckLocationFromQuery = QueryParamTruckLocation();
    let GUIDFromQuery = QueryParamGUID();

    if(GUIDFromQuery !== null) {
        localStorage.setItem('refreshToken', GUIDFromQuery)
    }

    const notLoggedIn = (!authToken || !loggedIn)
    const trySessionOrGUIDLogin = notLoggedIn && 
            (
                (localStorage.getItem('refreshToken') && (localStorage.getItem('rememberMe') && 
                localStorage.getItem('rememberMe') === "true")
            ) 
            || GUIDFromQuery !== null);
    const client = createApolloClient()
    const manualSearchFormRef = useRef(null)
  
    // Initialize authorization callbacks to handle what happens to this component state when
    // login succeeds or fails (show login modal or don't)
    Auth.getInstance().setCallbacks({
      success: () => {
        if (!loggedIn) {
          setLoggedIn(true)
        } 
      },
      logOutUI: () => {
        setLoggedIn(false)
      }
    })
  
    useEffect(() => {
      if (trySessionOrGUIDLogin) {
        Auth.getInstance().tryLocalStorageLogin(GUIDFromQuery ? true : false)
      }
    }, [loggedIn])
  
    if (trySessionOrGUIDLogin) {
      return (
        <div className={styles.mainContainer}
          style={{
            color: appStyle.color.text,
            backgroundColor: appStyle.color.background.main,
            fontSize: appStyle.text.large,
            display: 'flex',
            justifyContent: 'center'
          }}>
          <div style={{
            alignSelf: 'center'
          }}>
            Loading...
          </div>
        </div>
      )
    }

    if (notLoggedIn) {
        return <Login setLoggedIn={setLoggedIn}/>
    }

    return (
      <ApolloProvider client={client}>
        <div 
          className={styles.mainContainer}
          style = {{
            color: appStyle.color.text,
            backgroundColor: appStyle.color.background.main,
            fontSize: appStyle.text.regular
          }}>
          <AppHeader />
          <div className={styles.searchContainer}>
            <ManualLoadSearch
              ref={manualSearchFormRef}
              initSearchOrigin={TruckLocationFromQuery.TruckLocation}
              initSearchDest={TruckLocationFromQuery.TruckDestination} />
          </div>
          <div className={styles.loadsContainer}>
            <Loads 
              manualSearchFormRef={manualSearchFormRef}/>
          </div>
        </div>
      </ApolloProvider>
    )
  }

export default BaseRoute