import React, { useState, useContext, useRef } from 'react'
import { AppStyleContext } from 'contexts/AppStyleContext'
import LoadDetails from './LoadDetails'
import DeliveryDays from './DeliveryDays'
import TimeAgo from './TimeAgo'
import LabeledValue from './LabeledValue'
import LoadPlace from './LoadPlace'
import ScoreStarRating from './ScoreStarRating'
import PlanetPurple from 'images/planet_purple.svg'
import PlanetBlue from 'images/planet_blue.svg'
import PlanetGray from 'images/planet_gray.svg'
import ExpectedMouseoverDetails from './ExpectedMouseoverDetails'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { Label, Popover, Position, PopoverInteractionKind, PopoverPosition } from '@blueprintjs/core'
import styles from 'css/modules/Load.module.css'

const Load = ({load, index, style, renderOpened, openResizeList, destOnlySearch, manualSearchFormRef}) => {
  const [open, setOpen] = useState(renderOpened)
  const [detailHighlight, setDetailHighlight] = useState(false)
  const [highlight, setHighlight] = useState(false)
  const [actualScore, setActualScore] = useState(null)
  const [offerOverride, setOfferOverride] = useState(null)
  const offerOverrideInputRef = useRef(null)
  const { appStyle } = useContext(AppStyleContext)

  const { all_in_rate, contact, calculated_miles_estimated, calculated_practical_miles,
    calculated_short_miles, comment, complete, contact_id, created_at, updated_at,
    date_start, delivery_date, destination_city, destination_state, distance,
    equipment, equipment_category, height, lane_id, length, load_count, market_percentage,
    origin_city, origin_state, post_type, rate, score, stops, tracking_number, tw_final_score,
    expected_score, expected_allin, spot_indicator, confidence, indifference } = load

  const { contact_name, comp_city, comp_state, phone } = contact

  // Three different score types used depending on user input and data available for the load
  const SCORE_TYPES = {
    OFFER_OVERRIDE: 0,
    QUOTE: 1,
    EXPECTED: 2
  }

  const _setOpen = (index) => {
    openResizeList(index)
    setOpen(true)
  }

  
  let origin_pod = ''
  if (load.origin_low_pod && load.origin_low_pod.name) {
    origin_pod = load.origin_low_pod.name  
  }
  
  let destination_pod = ''
  if (load.destination_low_pod && load.destination_low_pod.name) {
    destination_pod = load.destination_low_pod.name
  }

  let offerOverrideScore
  if (offerOverride && indifference) {
    offerOverrideScore = offerOverride - indifference
  }

  let offerHighlight
  if(actualScore) {
    if (actualScore < 0) {
      offerHighlight = appStyle.color.highlight.red
    } else if (actualScore >= 0) {
      offerHighlight = appStyle.color.highlight.green
    } else {
      offerHighlight = '#233D5F'
    } 
  }

  let shared_comp_name
  if (contact) {
    if(contact.shared_comp_name) {
      shared_comp_name = contact.shared_comp_name
    }
    shared_comp_name = contact.comp_name
  }

  let twMiles
  if (load.twScore && load.twScore.miles_loaded) {
    twMiles = load.twScore.miles_loaded
  }

  let numDeliveryDays
  let milesPerDay = 500
  let numDaysMinimum = 0.8
  if (twMiles) {
    numDeliveryDays = (twMiles/milesPerDay).toFixed(1)
    if (numDeliveryDays < numDaysMinimum) {
      numDeliveryDays = numDaysMinimum
    }
    // remove decimal for days if .0
    if(numDeliveryDays % 1 === 0) {
      numDeliveryDays = Math.round(numDeliveryDays)
    }

  }

  let twEmptyMiles
  if (load.twScore && load.twScore.miles_empty) {
    twEmptyMiles = load.twScore.miles_empty
  }
  

  const confidenceMsg = () => {
    if(expected_allin) {
      if (confidence >= 3 && confidence <= 4) {
        return "Few Observations"
      }
      if (confidence >= 5 && confidence <= 6) {
        return "Active Lane"
      }
      if (confidence >= 7) {
        return "Highest Confidence"
      }
    }
    return null
  }

  const currentScoreType = () => {
    if(offerOverrideScore) {
      return SCORE_TYPES.OFFER_OVERRIDE
    }
    if (tw_final_score) {
      return SCORE_TYPES.QUOTE
    }
    if (expected_score) {
      return SCORE_TYPES.EXPECTED
    }
    return null
  }

  // uses the manualSearchFormRef to populate fields from this load
  const sendLoadUpToSearchForm = () => {
    const hasRate = rate !== 0
    const hasExpected = expected_allin !== null
    let searchQuoteValue = null
    
    if (hasRate) {
      searchQuoteValue = rate
    } else if (hasExpected) {
      searchQuoteValue = expected_allin
    }

    manualSearchFormRef.current.populateSearchFields(
      `${origin_city}, ${origin_state}`,
      `${destination_city}, ${destination_state}`,
      searchQuoteValue, !destOnlySearch)
      // !destOnlySearch bool being passed to Manual load search controls
      // just to specify that we DONT want to move origin up to empty
      // if empty blank and it's destination only search.
      // This is in place just to keep scores in line in the top area with the load
      // when using arrow button on load.
  }
  
  return (
    <div className={styles.mainContainer} style={{
      marginTop: index !== 0 && 30,
      ...style,
      height: open ? 308 : 214
      }}>
      <div className={styles.topLoadHeaderRow}>
        <div className={styles.companyNameItem}>
          { `${index + 1}) ${shared_comp_name}` }
        </div>
        <div className={styles.pickupLabel}>
          Pickup
        </div>
        <div className={styles.pickupDate}>
          <span>
            { moment(date_start).format('ddd') }
          </span>
            { moment(date_start).format('MM/DD') }
        </div>
      </div>
      <div className={styles.origin}>
        <LoadPlace
          label={'Orig'}
          city={origin_city}
          state={origin_state}
          pod={origin_pod } />
      </div>
      <div className={styles.empty}>
        <LabeledValue label={'Empty'} 
          value={twEmptyMiles && !destOnlySearch ? twEmptyMiles : '-'} />
      </div>
      <div className={styles.score}>
        <div style={{position: 'relative'}}>
          <div>
            <img className={styles.planet} src={tw_final_score ? PlanetPurple : expected_score ? PlanetBlue : PlanetGray} />
          </div>
          <div style={{
            color: offerHighlight
          }}
          className={styles.scoreHighlight}>
            { actualScore ? 
              `${actualScore < 0 ? '-' : '+'}$${Math.abs(Math.round(actualScore))}` : '-' }  
          </div>
        </div>
      </div>
      <div className={styles.dest}>
        <LoadPlace
          label={'Dest'}
          city={destination_city}
          state={destination_state}
          pod={destination_pod} />
      </div>
      <div className={styles.miles}>
        <LabeledValue label={
          numDeliveryDays &&
          <span style={{ fontSize: 10 }}>
            {`${numDeliveryDays} days`}
          </span> } 
          value={twMiles ? twMiles : '-'} />
      </div>
      <div className={styles.populateLoadSearch} onClick={sendLoadUpToSearchForm}>
        <FontAwesomeIcon icon='arrow-up' className={styles.searchUpArrow} />
      </div>
      <div className={styles.contact}>
        <LabeledValue 
          label={<TimeAgo timestamp={updated_at} />} 
          value ={
            <a className={styles.phoneLink} href={`tel:${phone.replace(/\(|\)|\s|\-/g, '')}`}>
              {phone}
            </a>
          }/>
      </div>
      <div className={styles.expected}
        style={{ color: !offerOverride && !tw_final_score && expected_allin && offerHighlight }}>
          <div>
            <Popover
              position={Position.BOTTOM}
              interactionKind={PopoverInteractionKind.HOVER_TARGET_ONLY}
              hoverOpenDelay={100}
              hoverCloseDelay={100}>
              <LabeledValue
                label={`Estimate`}
                value={ expected_allin ? 
                      `$${Math.abs(Math.round(expected_allin))}` : 'N/A' } />
              <ExpectedMouseoverDetails confidence={confidenceMsg()} />
            </Popover>
          </div>
      </div>
      <div className={styles.offer}>
        <div className={styles.offerInputContainer}>
        <LabeledValue label={"Wiz Calc"} value={
          <input 
            ref={offerOverrideInputRef}
            className={styles.offerTextInput}
            type={"number"}
            min={0}
            max={9999}
            placeholder={rate > 0 ? Math.abs(rate) : expected_allin ? expected_allin : '-'}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                let manualOffer = offerOverrideInputRef.current.value
                if(manualOffer !== '') {
                  let parsedOffer = parseInt(manualOffer)
                  if (parsedOffer > 0 && parsedOffer <= 9999) {
                    setOfferOverride(parseInt(manualOffer))
                  } else {
                    offerOverrideInputRef.current.value = ''
                    setOfferOverride(null)
                  }
                } else {
                  setOfferOverride(null)
                }
                offerOverrideInputRef.current.blur()
              }
            }}
            onClick={() => {
              offerOverrideInputRef.current.value = ''
              setOfferOverride(null)
            }}/>
        } />
        </div>
      </div>
      <div className={styles.quote}
          style={{
            color: !offerOverride && tw_final_score && offerHighlight
          }}>
            <LabeledValue label={"Quote"} value={ rate !== 0 ? `$${Math.round(rate)}` : '-' } />
        </div>
      <div className={styles.ratingSpacer} />
      <div className={styles.expectedRating}>
        {
          (currentScoreType() === SCORE_TYPES.EXPECTED) &&
            <ScoreStarRating score={actualScore} />
        }
      </div>
      <div className={styles.offerRating}>
        {
          (currentScoreType() === SCORE_TYPES.OFFER_OVERRIDE) &&
            <ScoreStarRating score={actualScore} />
        }
      </div>
      <div className={styles.quoteRating}>
        {
          (currentScoreType() === SCORE_TYPES.QUOTE) &&
            <ScoreStarRating score={actualScore} />
        }
      </div>
      <div className={styles.delivers}>
        <DeliveryDays
          startDate={date_start}
          setDisplayedScore={setActualScore}
          expectedDays={numDeliveryDays}
          score={(() => {
            if(offerOverrideScore) {
              return offerOverrideScore
            }
            if (tw_final_score) {
              return tw_final_score
            }
            if (expected_score) {
              return expected_score
            }
            return null
          })()}
        />
      </div>
      { !open && 
        <div className={styles.details}
            onClick={()=>_setOpen(index)}
            onMouseEnter={()=> setDetailHighlight(true)}
            onMouseLeave={()=> setDetailHighlight(false)}>
              <div
                onClick={()=>_setOpen(index)}
                onMouseEnter={()=> setDetailHighlight(true)}
                onMouseLeave={()=> setDetailHighlight(false)}
                className={styles.showDetailsLabel}
                style={{ textDecoration: detailHighlight ? 'underline' : '' }}>
                Details
              </div>
          </div>
      }
      {
        open && <LoadDetails load={load} twScore={load.twScore} />
      }
    </div>
  )
}

export default Load