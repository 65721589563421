import React, { useContext } from 'react'
import { LoadFilterContext } from 'contexts/LoadFilterContext'
import { LoadSearchContext } from 'contexts/LoadSearchContext'
import styles from 'css/modules/WhatsBestHeader.module.css'

const WhatsBestHeader = ({ whatsBestInfo, toggleLoadsView, routeLoadsNoResults }) => {
  const { addFilter } = useContext(LoadFilterContext)
  const { search, setSearch, hasDestOnlySearch } = useContext(LoadSearchContext)
  const destOnly = hasDestOnlySearch()

  if (routeLoadsNoResults) {
    return (
      <div className={styles.mainContainer}>
        What's Best: Destination Not Found
      </div>
    )
  }

  if(whatsBestInfo == null) {
    return (
      <div className={styles.mainContainer}>
        What's Best: No Results Found
      </div>
    )
  }

  const loadPodField = destOnly ? 'origin_low_pod' : 'destination_low_pod'

  const { pod, score } = whatsBestInfo
  const greenScore = score >= 100
  const redScore = score <= -100

  const scoreColorClass = (() => {
    if (greenScore) {
      return styles.positiveScore
    }
    if (redScore) {
      return styles.negativeScore
    }
    return null
  })()

  const absValScore = Math.abs(score)
  const onClick = () => {
    addFilter(loadPodField, { name: pod })
    toggleLoadsView()
  }
  return (
    <div className={styles.mainContainer} onClick={onClick}>
      What's Best:
      <span className={scoreColorClass}>
        { ` ${ score > 0 ? '+$' : '-$' }${absValScore}` }
      </span>
      { ` ${destOnly ? 'from' : 'to'} ${pod}` }
    </div>
  )
}

export default WhatsBestHeader
