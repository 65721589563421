import { Position, Tooltip } from '@blueprintjs/core'
import React, { useState, useEffect } from 'react'
import ReadOnlyInput from './ReadOnlyInput'

const ScoreInput = ({ expected, quote }) => {
  const scoreAvailable = Number.isInteger(expected) && Number.isInteger(quote)
  
  const calculateScore = () => {
    return scoreAvailable ? (quote - expected) : null
  }
  
  const [score, setScore] = useState(calculateScore())

  useEffect(() => {
    setScore(calculateScore())
  }, [expected, quote])

  return <Tooltip content="Freight Quality" position={Position.TOP}><ReadOnlyInput value={score || ''} /></Tooltip>

}

export default ScoreInput