import React from 'react'
import LabeledValue from './LabeledValue'
import styles from 'css/modules/LoadPlace.module.css'

const LoadPlace = ({ label, city, state, pod }) => {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.label}>
        { label }
      </div>
      <LabeledValue label={
        <div className={styles.pod}>
          <div>
            { pod }
          </div>
        </div>
        } value={
          <div className={styles.cityStateContainer}>
            <div className={styles.cityContainer}>
              { city }
            </div>
            <div className={styles.state}>
              { state }
            </div>
          </div>  
        } />
    </div>
  )
}

export default LoadPlace