import React, { useContext, useState, useRef } from 'react'
import { LoadSearchContext } from 'contexts/LoadSearchContext'
import PickupDateFilterControl from './filter/PickupDateFilterControl'
import FilterActiveMarkets from './filter/FilterActiveMarkets'
import ActiveMarketFilterControl from './filter/ActiveMarketFilterControl'
import WhatsBestHeader from './filter/WhatsBestHeader'
import ShowingDialogControl from './ShowingDialogControl'
import { motion } from 'framer-motion'
import LoadList from './LoadList'
import _ from 'lodash'
import styles from 'css/modules/LoadsTable.module.css'

const LoadsTable = ({ loads, filteredLoads, activeMarkets, manualSearchFormRef }) => {
  const loadListContainerRef = useRef(null)
  const { search, setSearch, hasDestOnlySearch } = useContext(LoadSearchContext)
  const destOnly = hasDestOnlySearch()
  const [loadingMessage, setLoadingMessage] = useState(null)
  const [whatsBestInfo, setWhatsBestInfo] = useState(null)
  const [routeLoadsNoResults, setRouteLoadsNoResults] = useState(false)

  // viewLoads just a bool switch for going back and forth between loads and pods views
  const [viewLoads, setViewLoads] = useState(false)

  const scrollLoadsToTop = () => {
    try {
      loadListContainerRef.current.scrollTo(0,0)
    } catch (error) {
      // do nothing, IE does not support ScrollTo, also might not be initialized yet
    }
  }

  const toggleLoadsView = () => {
    setViewLoads(true)
    scrollLoadsToTop()
  }

  return (
    <FilterActiveMarkets activeMarkets={activeMarkets}>
      {({activeMarkets,
        showAllMarkets,
        showOnlyActiveMarkets,
        observationsThreshold,
        setObservationsThreshold}) =>
          <div className={styles.mainContainer}>
            <button className={[styles.backToMarketsButton, !viewLoads && styles.hide].join(' ')}
              onClick={() => setViewLoads(false)}>
              { `Back to Markets` }
            </button>
            <div className={[styles.loadListContainer, !viewLoads && styles.hide].join(' ')}>
              <LoadList
                loads={loads}
                filteredLoads={filteredLoads}
                loadingMessage={loadingMessage}
                manualSearchFormRef={manualSearchFormRef}
                loadListContainerRef={loadListContainerRef} />
            </div>
            <div className={[styles.loadFilterControls, viewLoads && styles.hide].join(' ')}>  
                <motion.div
                  initial={{opacity: 0}}
                  animate={{opacity: 1}}>
                  <div className={styles.lighterBackground}>
                    <ShowingDialogControl
                      showAllMarkets={showAllMarkets}
                      showOnlyActiveMarkets={showOnlyActiveMarkets} />
                    <WhatsBestHeader
                      whatsBestInfo={whatsBestInfo}
                      toggleLoadsView={toggleLoadsView} 
                      routeLoadsNoResults={routeLoadsNoResults} />
                  </div>
                  <PickupDateFilterControl
                    loads={loads}
                    activeMarkets={activeMarkets}
                    setWhatsBestInfo={setWhatsBestInfo}  
                    routeLoadsNoResults={routeLoadsNoResults}
                    setRouteLoadsNoResults={setRouteLoadsNoResults}
                    />
                  <div className={styles.scrollableActiveMarketsContainer}>
                    <ActiveMarketFilterControl
                      activeMarkets={activeMarkets}
                      showAllMarkets={showAllMarkets}
                      showOnlyActiveMarkets={showOnlyActiveMarkets}
                      observationsThreshold={observationsThreshold}
                      setObservationsThreshold={setObservationsThreshold}
                      loads={loads}
                      toggleLoadsView={toggleLoadsView}
                      destOnly={destOnly} 
                      routeLoadsNoResults={routeLoadsNoResults}/>
                  </div>
                </motion.div>  
            </div>
        </div>
      }
    </FilterActiveMarkets>
  )
}

export default LoadsTable
