import React from 'react'
import FetchLoads from './util/FetchLoads'
import ScoreLoads from './util/ScoreLoads'
import FilterLoads from './util/FilterLoads'
import LoadsTable from './LoadsTable'
import FetchActiveMarkets from './util/FetchActiveMarkets'
import { LoadFilterProvider } from 'contexts/LoadFilterContext'

const Loads = ({ manualSearchFormRef }) => {
  return (
    <FetchLoads>
      {({ loads }) => 
        <ScoreLoads loads={loads}>
          {({ loads }) =>
          <FetchActiveMarkets>
            {({ activeMarkets }) => 
            <LoadFilterProvider>
              <FilterLoads loads={loads}>
                {({ loads, filteredLoads }) => 
                  <LoadsTable
                    manualSearchFormRef={manualSearchFormRef}
                    loads={loads}
                    filteredLoads={filteredLoads}
                    activeMarkets={activeMarkets} />
                }
              </FilterLoads>
            </LoadFilterProvider>
            }
          </FetchActiveMarkets>
          }
        </ScoreLoads>
      }
    </FetchLoads>
  )
}

export default Loads