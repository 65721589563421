import React, { useEffect, useContext } from 'react'
import { SettingsContext } from 'contexts/SettingsContext'
import moment from 'moment'
import _ from 'lodash'
import Auth from 'utils/Auth'
import { FormGroup, FormLabel, Button, Menu, MenuItem, Popover, Position } from '@blueprintjs/core'

const StudyPicker = ({
  setEquipment,
  setStudyResolution,
  selectedDivision,
  setSelectedDivision,
  settingsLoaded,
  setSettingsLoaded,
  divisions,
  setDivisions,
  selectedStudy,
  setSelectedStudy
}) => {
  const { settings } = useContext(SettingsContext)
  const { AUTH_URL } = settings

  useEffect(() => {
    if (!settingsLoaded) {
      initializeDivisionDropdownData()
    }
  }, [])

  // Pulls study information from TW and does a bunch of processing on the 4 digit study codes to figure
  // out what should be displayed to the user..
  const initializeDivisionDropdownData = () => {
    fetch(`${AUTH_URL}/defaults`, {
      headers: {
        Authorization: Auth.getInstance().getToken()
      }
    })
    .then(divisionsResponse => divisionsResponse.json())
    .then(divisionsData => {
      // set the app default division
      let currentYear = parseInt(moment().format('YYYY'))
      let currentDecade = parseInt(currentYear/10) * 10 //2029 -> 2020, 2030 -> 2030
      let currentYearDigit = currentYear % 10 // 2021 => 1
      let lastDecade = (parseInt(moment().subtract(10, 'years').format('YYYY')))/10 * 10
      let oldStudyThresholdDate = parseInt(moment().subtract(3, 'months').format('YYYYMM')) // 3 months ago
      let studyTypes = ['F', 'Q', 'B', 'R', 'M'] // specifies the order of the study prioritization, last highest priority
      let divisionsList = divisionsData.divisions
      // Run through each division
      for (let divisionIndex = 0; divisionIndex < divisionsList.length; divisionIndex++) {
        let _division = divisionsList[divisionIndex]
        let { name, equipment, defaultStudy, appDefault, studies, resolution } = _division
        let studyComparisons = {}
        studyComparisons['MOST_RECENT'] = null
        // list to contain all old and disabled studies to remove later
        let studyRemovalList = []
        // partition studies into their respective types with a numerical value, i.e. 11M0 -> 202011
        studyTypes.forEach(studyType => {
          studyComparisons[studyType] = {}
          studies.filter(study => study.includes(`${studyType}`)).forEach(study => {
            // determine if this study is in this decade or last
            let studyYearDigit = parseInt(study.substring(3,4))

            // studies with year digit greater than this year are likely to be in the last decade and not this one
            let decade = currentDecade
            if(studyYearDigit > currentYearDigit) {
              decade = lastDecade
            }
            let fullStudyYear = decade + studyYearDigit
            let studyDateValue = parseInt(`${fullStudyYear}${study.substring(0,2)}`)

            // Bypass [filter out studies that are > 2 months old]
            studyComparisons[studyType][study] = parseInt(`${fullStudyYear}${study.substring(0,2)}`)

            // filter out studies that are > 2 months old
            /*
            if (studyDateValue > oldStudyThresholdDate) {
              studyComparisons[studyType][study] = parseInt(`${fullStudyYear}${study.substring(0,2)}`)
            } else {
              // remove from the client's available dropdown
              studyRemovalList.push(study)
            }*/

          })

          // finds the most recent study with the order specified by studyTypes
          Object.keys(studyComparisons[studyType]).forEach(studyKey => {
            let studyVal = studyComparisons[studyType][studyKey]
            if (studyComparisons['MOST_RECENT'] === null || Object.values(studyComparisons['MOST_RECENT'])[0] <= studyVal) {
              studyComparisons['MOST_RECENT'] = {}
              studyComparisons['MOST_RECENT'][studyKey] = studyVal
            }
          })
        })
        
        // Set the lb default study for this division
        let lbDefaultStudy
        if (studyComparisons['MOST_RECENT'] !== null) {
          lbDefaultStudy = Object.keys(studyComparisons['MOST_RECENT'])[0]
        }

        // default divisions are specified by TW
        if (appDefault || divisionsList.length === 1) {
          // no LB default found, default to TW
          if (!lbDefaultStudy) {
            lbDefaultStudy = defaultStudy
          }
          // set client state
          setSelectedDivision(name)
          setEquipment(equipment)
          setSelectedStudy(lbDefaultStudy)
          setStudyResolution(resolution)
        }
        // filter out old or invalid studies from the client view for this division,
        // If there aren't any studies after filter, don't filter (old studies better than no studies)
        let filteredStudies = _division.studies.filter(study => !(studyRemovalList.includes(study)))
        if (filteredStudies.length > 0) {
          _division.studies = filteredStudies
        }
      }
      //console.log(divisionsList)
      setDivisions(divisionsList)
      setSettingsLoaded(true)
    })
  }

  const DivisionSubMenu = ({division}) => {
    const { name, studies, equipment, resolution } = division
    return (
      <MenuItem key={name} text={name} style={{ width: 75 }}>
        {
          studies.map(study => {
            return (
              <MenuItem key={`${name}${study}`} text={study} onClick={() => {
                setSelectedDivision(name)
                setStudyResolution(resolution)
                setSelectedStudy(study)
                setEquipment(equipment)
              }}/>
            )
          })
        }
      </MenuItem>
    )
  }

  if (!divisions) {
    return <div></div>
  }
  
  let orderedDivisions = _.orderBy(divisions, ['name'], ['desc'])

  const FormLabel = ({value}) => {
    return (
      <div style={{
        paddingLeft:10,
        paddingRight:10,
        width: 40
      }}>
        {value}
      </div>
    )
  }

  return (
    <FormGroup
      inline={true}
      label={<FormLabel value={''} />}>
        <Popover 
          position={Position.BOTTOM}
          content={
          <Menu>
            {
              orderedDivisions.map(division => <DivisionSubMenu division={division} key={division.name} /> )
            }
          </Menu>}>
          <Button text={`${selectedDivision} ${selectedStudy}`} style={{width: '100%', textAlign: 'center'}} />
        </Popover>
    </FormGroup>
  )
}

export default StudyPicker
