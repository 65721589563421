import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import { InputGroup, Spinner, Intent } from '@blueprintjs/core'
import CalculatorImg from 'images/calculator.svg'
import styles from 'css/modules/ManualLoadSearch.module.css'
// Quote input will adjust the filter
const QuoteInputAndCalcButton = forwardRef((props, ref) => {
  const { value,
    setQuoteInputOverride,
    loadingMilesAndExpected,
    baseline,
    ldedMiles,
    fuelSurcharge,
    marketRate,
    marketRateLow,
    marketRateHigh } = props
  const [quote, setQuote] = useState(null)
  const [rpbm, setRpbm] = useState(null)
  const [linehaul, setLinehaul] = useState(null)
  const [fsurCpm, setFsurCpm] = useState(null)
  const [mrktMid, setMrktMid] = useState(null)
  const [mrktLow, setMrktLow] = useState(null)
  const [mrktHigh, setMrktHigh] = useState(null)
  const QUOTE_UPPER_BOUND = 50000
  const quoteReady = Number.isInteger(quote)
  const miles = parseInt(ldedMiles)
  const _baseline = parseInt(baseline)
  const milesReady = !isNaN(miles)
  const baselineReady = !isNaN(_baseline)
  const _fuelSurcharge = parseInt(fuelSurcharge)
  const fuelSurchargeReady = !isNaN(_fuelSurcharge)
  const _marketRate = parseInt(marketRate)
  const marketRateReady = !isNaN(_marketRate)
  const _marketRateLow = parseInt(marketRateLow)
  const marketRateLowReady = !isNaN(_marketRateLow)
  const _marketRateHigh = parseInt(marketRateHigh)
  const marketRateHighReady = !isNaN(_marketRateHigh)
  
  useEffect(() => {
    setQuote(value)
    //setQuoteInputOverride(null)
  }, [value])

  useEffect(() => {
    setRpbm(calcNewRpbm())
    setLinehaul(calcNewlinehaul())
    setFsurCpm(calcNewFsurCpm())
    setMrktMid(calcNewMarketMid())
    setMrktLow(calcNewMarketLow())
    setMrktHigh(calcNewMarketHigh())
  }, [baseline])


  useEffect(() => {
    if (quote === null || quote === value) {
      setRpbm(calcNewRpbm())
      setLinehaul(calcNewlinehaul())
      setFsurCpm(calcNewFsurCpm())
      setMrktMid(calcNewMarketMid())
      setMrktLow(calcNewMarketLow())
      setMrktHigh(calcNewMarketHigh())
    }
  }, [quote])

  // Rarly used react hook here, essentially exposes the clearOnClick method
  // to the parent component via ref. Must be used to expose state to parent.
  // In this case, the quote input, since it is only partially controlled
  useImperativeHandle(ref, () => ({
    clear: clearOnClick,
    hasValue: () => quote !== null,
    setQuote: (newVal) => setQuote(newVal)
  }))

  const updateQuote = (e) => {
    if (e.target.value !== '') {
      let newQuote = parseInt(e.target.value)
      if (Number.isInteger(newQuote) && newQuote <= QUOTE_UPPER_BOUND) {
        setQuote(newQuote)
      }
    } else {
      setQuote(null)
      setQuoteInputOverride(null)
    }
  }

  const handleEnter = (e, enterOverride) => {
    const enterPressed = enterOverride || (e.key === 'Enter')
    const quoteEmpty = quote === null
    if (enterPressed) {
      if (quoteReady) {
        setQuoteInputOverride(quote)
        setRpbm(calcNewRpbm())
        setLinehaul(calcNewlinehaul())
        setFsurCpm(calcNewFsurCpm())
        setMrktMid(calcNewMarketMid())
        setMrktLow(calcNewMarketLow())
        setMrktHigh(calcNewMarketHigh())
      } else if (quoteEmpty) {
        setQuote(value) // on empty field and user hits enter, reset to tw quote
      }
    }
  }

  const clearOnClick = () => {
    setQuote(null)
    setQuoteInputOverride(null)
  }

  // either the provided quote or the baseline sent from TW
  const baseForCalculation = (() => {
      if (quoteReady) {
        return quote
      }
      if (baselineReady) {
        return _baseline
      }
      return null
    })()

  const calcNewRpbm = () => {
    if (baseForCalculation && milesReady) {
      return (Math.round((baseForCalculation/miles)*100) / 100).toFixed(2)
    }
    return ''
  }

  const calcNewlinehaul = () => {
    if (baseForCalculation && milesReady && fuelSurchargeReady) {
      return (Math.round(((baseForCalculation-fuelSurcharge)/miles)*100) / 100).toFixed(2)
    }
    return ''
  }

  const calcNewFsurCpm = () => {
    if (milesReady && fuelSurchargeReady) {
      return (Math.round((fuelSurcharge/miles)*100) / 100).toFixed(2)
    }
    return ''
  }

  const calcNewMarketMid = () => {
    if (milesReady && marketRateReady) {
      return (Math.round((marketRate/miles)*100) / 100).toFixed(2)
    }
    return ''
  }

  const calcNewMarketLow = () => {
    if (milesReady && marketRateLowReady) {
      return (Math.round((marketRateLow/miles)*100) / 100).toFixed(2)
    }
    return ''
  }

  const calcNewMarketHigh = () => {
    if (milesReady && marketRateHighReady) {
      return (Math.round((marketRateHigh/miles)*100) / 100).toFixed(2)
    }
    return ''
  }

  return (
    <>
      <div className={styles.quoteSearchInput}>
        <InputGroup
          value={quote || ''}
          type={'number'}
          onChange={updateQuote}
          onKeyUp={handleEnter}
          onClick={clearOnClick} />
      </div>
      <div className={styles.calculatorButton}>
        {
          loadingMilesAndExpected ?
            <Spinner size={30} intent={Intent.PRIMARY} />
          : 
            <img src={CalculatorImg} alt={'Click to recalculate target'}
                onClick={() => handleEnter(null, true)} />
        }
      </div>
      <div className={styles.ratesLabelContainer}>
        <div className={styles.rpbmCpm}>
          {`TOTL: ${rpbm}`}
        </div>
        <div className={styles.linehaulCpm}>
          {`RPBM: ${linehaul}`}
        </div>
        <div className={styles.fsurCpm}>
          {`FSUR: ${fsurCpm}`}
        </div>
        <div className={styles.marketLow}>
          {`LOWR: ${mrktLow}`}
        </div>
        <div className={styles.marketMid}>
          {`MRKT: ${mrktMid}`}
        </div>
        <div className={styles.marketHigh}>
          {`HIGH: ${mrktHigh}`}
        </div>
      </div>
    </>
  )
})

export default QuoteInputAndCalcButton