import Auth from './Auth'
import _ from 'lodash'

// Helper class for user tracking throughout the app
const UserEvent = () => {
  const postEvent = (eventType, payload) => {
    fetch(`${process.env.REACT_APP_AUTH_URL}/event`, {
      method: 'POST',
      headers: {
        Authorization: Auth.getInstance().getToken(),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        event: eventType,
        payload: payload
      })
    }).then(response => null)
  }

  // creates the load stats payload for user activity tracking
  const generateLoadStats = (loads) => {
    let totalCount = loads.length
    let withQuotes = loads.filter(l => l.rate > 0).length
    let withQuoteScore = loads.filter(l => l.tw_final_score !== null).length
    let withExpectedScore = loads.filter(l => l.expected_score !== null && l.tw_final_score === null).length
    let withNoScore = loads.filter(l => l.expected_score === null && l.tw_final_score === null).length
    let withScoreException = loads.filter(l => l.twScore.ClassName !== undefined).length
    return {
      totalCount,
      withQuotes,
      withQuoteScore,
      withExpectedScore,
      withNoScore,
      withScoreException
    }
  }

  // creates the search payload for user activity tracking
  const generateSearchPayload = (search) => {
    return {
      origin:  {
        citySt: `${search.truck.city}, ${search.truck.state}`,
        pods: search.pods || []
      },
      destination: {
        citySt: `${search.truck.destCitySt}`,
        pods: search.destPods || []
      },
      equipment: `${search.truck.equipment}`,
      loh: search.minLOH,
      quotesOnly: search.quotesOnly,
      division: search.division,
      study: search.study
    }
  }

  const postSearchEvent = (payload) => {
    try {
      postEvent('search', { search: generateSearchPayload(payload.search), results: generateLoadStats(payload.loads)})
    } catch (error) {
      // don't error out if something bad happens while trying to generate stats
    }
  }
  const postLoginEvent = (source) => {
    try {
      postEvent('login', {source: source})
    } catch (error) {  }
  }

  return {
    postSearchEvent: postSearchEvent,
    postLoginEvent: postLoginEvent
  }
}

export default UserEvent