import UserEvent from './UserEvent'

/**
 * Convenience method for user authentication
 * 
 */
let instance
let token
let refresh
let callbacks = {
  success: () => {}, 
  logOutUI: () => {}
}
let tabMarker = Math.round((Math.random() * 10000000000000)).toString()
let checkActiveTab
const Auth = (() => {
  const createInstance = () => {
    
    const startActiveTabCheck = () => {
      if (!checkActiveTab) {
        localStorage.setItem('activeTab', tabMarker)
        checkActiveTab = setInterval(() => {
          if(localStorage.getItem('activeTab') !== tabMarker){
            _logOut()
          }
        }, 10000)
      }
      // check if this is the active tab, if not, log out
    }

    const stopActiveTabCheck = () => {
      if (checkActiveTab) {
          clearInterval(checkActiveTab)
          checkActiveTab = null
      }
    }

    const startLoginRefreshInterval = () => {
      if (!refresh) {
        refresh = setInterval(refreshLogin, 600000) // 10 minutes 600000
      }
    }

    const stopLoginRefreshInterval = () => {
      if (refresh) {
        clearInterval(refresh)
        refresh = null
      }
    }

    const _logOut = async (hardReset=false) => {
      stopLoginRefreshInterval()
      //stopActiveTabCheck()
      token = null
      if (hardReset) {
        localStorage.clear()
        let domainWithoutQueryParams = window.location.href.split('?')[0]
        window.location.replace(domainWithoutQueryParams)
      } else {
        callbacks.logOutUI()
      }
      
    }
    
    const refreshLogin = async () => {
      let refreshToken = localStorage.getItem('refreshToken')
      if (!refreshToken) {
        _logOut()
        return {error:true}
      }
      return fetch(`${process.env.REACT_APP_AUTH_URL}/refresh`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          token: refreshToken
        })
      })
      .then(response => response.json())
      .then(data => {
        if(!data.error) {
          token = data.jwt
          localStorage.setItem('refreshToken', data.refreshToken)
          callbacks.success()
        } else {
          _logOut(true)
        }
        return data
      })
    }
    return {
      logIn: async ({carrier, username, password}) => {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ carrier, username, password })
        }
        return await fetch(`${process.env.REACT_APP_AUTH_URL}/login`, requestOptions)
          .then(response => response.json())
          .then(data => {
            if (data.error) {
              _logOut()
            } else {
              token = data.jwt
              localStorage.setItem('refreshToken', data.refreshToken)
              localStorage.setItem('activeTab', tabMarker)
              startLoginRefreshInterval()
              //startActiveTabCheck()
              callbacks.success()
              UserEvent().postLoginEvent('Front Page')
            }
            return data
          })
      },
      getToken: () => token,
      tryLocalStorageLogin: (autoLogin = false) => {
        let localStorageToken = localStorage.getItem('refreshToken')
        let rememberMe = (localStorage.getItem('rememberMe') && localStorage.getItem('rememberMe') === "true") || autoLogin
        if(!localStorageToken || (!rememberMe)) {
          _logOut()
          return
        }
        if (checkActiveTab && localStorage.getItem('activeTab') !== tabMarker) {
          _logOut()
          return
        }
        refreshLogin().then(data => {
          if (!data.error) {
            if (autoLogin) {
              UserEvent().postLoginEvent('Truckload Wizard')
            } else if (rememberMe) {
              UserEvent().postLoginEvent('Front Page')
            }
            
            //startActiveTabCheck()
            startLoginRefreshInterval()
          }
        })
      },
      logOut: _logOut,
      setCallbacks: (_callbacks) => {
        callbacks = _callbacks
        return instance
      },
      getLoginInfo: () => {
        try {
          return JSON.parse(atob(token.split('.')[1]))
        } catch (e) {
          return null;
        }
      }
    }
  }
  return {
    getInstance: () => {
      if(!instance) {
        instance = createInstance()
      }
      return instance
    },
  }
})()

export default Auth