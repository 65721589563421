import React, { useState, useContext, createContext } from 'react'
import { SettingsContext } from './SettingsContext'
import Auth from 'utils/Auth'
const {default: PQueue} = require('p-queue')
const queue = new PQueue({concurrency: 8})
let lbiSpotIndicator = null

export const TWWebServicesContext = createContext()

export const TWWebServicesProvider = props => {
  const { settings } = useContext(SettingsContext)

  let fetchSpotIndicator = () => {
    fetch(`${settings.AUTH_URL}/spot_indicator`, {
      headers: {
        Authorization: Auth.getInstance().getToken()
      }
    })
    .then(spotIndicatorResponse => spotIndicatorResponse.json())
    .then(spotIndicator => { lbiSpotIndicator = spotIndicator })
  }
  fetchSpotIndicator()
  setInterval(fetchSpotIndicator, 1000 * 60 * 30) // refresh spot indicator 30 min

  // Scores a single load
  const twScoreLoad = async ({ truckCity, truckState, loadOriginCity, loadOriginState, loadDestinationCity, loadDestinationState, offer, division, study, equipment, resolution }) => {
    let empty = ""
    if(truckCity && truckState) {
      empty = `${truckCity},${truckState}`
    }
    let url = `${settings.AUTH_URL}/score?Empty=${empty}&Origin=${loadOriginCity},${loadOriginState}&Destination=${loadDestinationCity},${loadDestinationState}&Division=${division}&Study=${study}&Equipment=${equipment}&Resolution=${resolution}`
    if(offer > 0) {
      // Little bugfix for offers with decimals.. 
      let roundedOffer = Math.round(offer)
      if (!isNaN(roundedOffer)) {
        url += `&Linehaul=${roundedOffer}`
      }
    }
    
    return await fetch(encodeURI(url), {
      headers: {
        Authorization: Auth.getInstance().getToken()
      }
    })
    .then(response => response.json())
    .catch(error => null)
  }

  // Scores multiple loads
  const twScoreLoads = async (truck, loads, division, study, resolution, equipment, loadsStatusCallback=(obj)=>{}) => {
    let loadPromises = []
    let scoredLoads = []
    
    // Wait for the first spot indicator request if it hasn't finished yet
    // (Autosearch case)
    while(lbiSpotIndicator === null) {
      await new Promise(r => setTimeout(r, 100))
    }

    loads.forEach(load => {
      const { origin_city, origin_state, destination_city, destination_state, rate, equipment_category } = load
      let load_market_equipment = null
      try {
        load_market_equipment = equipment_category.split(' ')[0]
      } catch (error) {  }
      
      //make sure rate > 0 for score
      loadPromises.push(async () => {
        await twScoreLoad({
          truckCity: truck.city,
          truckState: truck.state,
          loadOriginCity: origin_city,
          loadOriginState: origin_state,
          loadDestinationCity: destination_city,
          loadDestinationState: destination_state,
          offer: rate,
          division,
          study,
          equipment,
          resolution
        }).then(scoreResponse => {
          // Put final score value into load data
          let roundedOffer = rate
          try {
            // first try to round the offer
            roundedOffer = Math.round(roundedOffer)
            if(roundedOffer >= 10000) {
              roundedOffer = 0
            }
          } catch (error) {  }
          
          // TW score when given an offer
          let twFinalScore = null
          let spotIndicatorPct = null
          let indifference = null
          if(scoreResponse != null && scoreResponse.indifference_withfuel) {
            indifference = scoreResponse.indifference_withfuel
          }
          
          // figure out the spot indicator percentage
          if(load_market_equipment) {
            let originPodName = load.origin_low_pod.name
            let spotIndicatorPctEquip = lbiSpotIndicator[`${({V: 'VANS', R: 'REFR', F: 'FLTS'})[load_market_equipment]}_raw_pct`]/100
            if(originPodName) {
              let originPodPct = lbiSpotIndicator[load_market_equipment][originPodName]
              if (originPodPct) {
                spotIndicatorPct = originPodPct
              } else {
                spotIndicatorPct = spotIndicatorPctEquip
              }
            } else {
              spotIndicatorPct = spotIndicatorPctEquip
            }
          }
          
          if(!spotIndicatorPct) {
            spotIndicatorPct = 1
          }
          
          // calculate posted offer score
          if(roundedOffer && roundedOffer > 0 && indifference) {
              twFinalScore = roundedOffer - indifference
          }
          
          // EXPECTED score when no offer
          let expdFinalScore = null
          let expectedAllin = null
          if(scoreResponse != null && scoreResponse.market && scoreResponse.market > 0 && scoreResponse.market_withfuel) {
            expectedAllin = Math.round(scoreResponse.market_withfuel * spotIndicatorPct)
          }
          
          // calculate expected score
          if(expectedAllin && indifference) {
            expdFinalScore = expectedAllin - indifference
          }
          
          let confidence = 0
          //Confidence level
          if(scoreResponse.confidence) {
            confidence = scoreResponse.confidence
          }

          let market_low
          //Confidence level
          if(scoreResponse.marketlow_withfuel) {
            market_low = scoreResponse.marketlow_withfuel
          }

          let market_high
          //Confidence level
          if(scoreResponse.markethigh_withfuel) {
            market_high = scoreResponse.markethigh_withfuel
          }

          if (confidence < 3) {
            expectedAllin = null
            expdFinalScore = null
          }

          scoredLoads.push({ 
            ...load,
            indifference: indifference,
            rate: roundedOffer,
            confidence: confidence,
            market_low: market_low,
            market_high: market_high,
            spot_indicator: spotIndicatorPct,
            expected_allin: expectedAllin,
            expected_score: expdFinalScore,
            twScore: scoreResponse,
            tw_final_score: twFinalScore
          })

          loadsStatusCallback(scoredLoads.length, loads.length)
        })
      })
      
    })
    
    queue.clear()
    return queue.addAll(loadPromises).then(() => scoredLoads)
  }

  const getSpotIndicatorPct = (pod, equipment) => {
    let pct = lbiSpotIndicator[equipment][pod]
    if (pct === undefined) {
      let fullEquip
      switch (equipment) {
        case 'V':
          fullEquip = 'VANS'
          break
        case 'R':
          fullEquip = 'REFR'
          break
        case 'F':
          fullEquip = 'FLTS'
          break
      }
      pct = (lbiSpotIndicator[`${fullEquip}_raw_pct`] * 0.01)
    }
    return pct
  }
  
  return (
    <TWWebServicesContext.Provider value={{ twScoreLoad, twScoreLoads, getSpotIndicatorPct }}>
      { props.children }
    </TWWebServicesContext.Provider>
  )
}

