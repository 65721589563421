import React, { useState, useRef, forwardRef, useImperativeHandle } from 'react'
import Autosuggest from 'react-autosuggest'
import { throttle } from 'throttle-debounce'

const CityAutoSuggest = forwardRef((props, ref) => {
  const { initialValue, onChangeCallback, placeholder } = props
  const [suggestions, setSuggestions] = useState([])
  const [currentValue, setCurrentValue] = useState(initialValue || '')

  useImperativeHandle(ref, () => ({
    clear: clearInputOnClick,
    setValue: (newVal) => setCurrentValue(newVal)
  }))

  const renderSuggestion = (suggestion) => {
    return <span>{suggestion.city}</span>
  }

  const onSuggestionsClearRequested = () => {
    setSuggestions([])
  }

  const clearInputOnClick = () => {
    setCurrentValue('')
    setSuggestions([])
    onChangeCallback('')
  }

  const getSuggestionValue = (suggestion) => {
    return suggestion.city
  }

  const onChange = (event, { newValue }) => {
    onChangeCallback(newValue)
    setCurrentValue(newValue)
  }

  const getUpdatedSuggestions = async (value) => { 
    if (value === "") {
      return []
    } 
    return await fetch(encodeURI(`${process.env.REACT_APP_AUTH_URL}/spelling?search=${value}`))
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        return []
      } else {
        return data.map((suggestion) => ({ city: suggestion }))
      }
    })
  }

  const onSuggestionsFetchRequested = async ({ value }) => {
    if (value.length > 1) {
      setSuggestions(await getUpdatedSuggestions(value))
    }
  }

  // register a reference for throttling server city spelling queries
  const throttledOnSuggestionsFetchRequested = useRef(throttle(500, value => onSuggestionsFetchRequested(value))).current

  return (
    <div>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={throttledOnSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        renderSuggestion={renderSuggestion}
        getSuggestionValue={getSuggestionValue}
        inputProps={{
          placeholder: placeholder,
          value: currentValue,
          onChange: onChange,
          onClick: clearInputOnClick,
          className: 'bp3-input',
          style: {
            width: 105,
            color: 'black'
          }
        }} />
    </div>
  )
})

export default CityAutoSuggest