import React from 'react'
import MouseoverPopupDetails from 'components/shared/MouseoverPopupDetails'

const ExpectedMouseoverDetails = ({confidence}) => {
  let confidenceMsg = 'Insufficient Statistics'
  if (confidence) {
    confidenceMsg = confidence
  }
  return (
    <MouseoverPopupDetails details={confidenceMsg}></MouseoverPopupDetails>
  )
}

export default ExpectedMouseoverDetails