import React from 'react'
import { FormGroup, InputGroup, Button, Menu, MenuItem, Popover, Position, Radio, RadioGroup, Checkbox } from '@blueprintjs/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from 'css/modules/ManualLoadSearch.module.css'

const SettingsToggle = ({
  quotesOnly,
  setQuotesOnly,
  setUserSelectedQuotesOrAll
}) => {
  return <FontAwesomeIcon icon='cogs' className={styles.settingsCog} />
  return (
    <Popover
      position={Position.BOTTOM}
      content={
        <Menu position={Position.BOTTOM}>
          <MenuItem key={'Search Type'} text={'Search Type'} position={Position.BOTTOM}>
            <MenuItem key={'InfiniteWisdom'} position={Position.BOTTOM} text={
              <RadioGroup
                onChange={() => {
                  setQuotesOnly(!quotesOnly)
                  setUserSelectedQuotesOrAll(true)
                }}
                selectedValue={quotesOnly ? 'qo' : 'all'}>
                  <Radio label={'Quotes'} value={'qo'} />
                  <Radio label={'All'} value={'all'} />
              </RadioGroup>
            }>
            </MenuItem>
          </MenuItem>
        </Menu>
      }>
        <FontAwesomeIcon icon='cogs' className={styles.settingsCog} />
    </Popover>
  )
}

export default SettingsToggle