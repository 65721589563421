import React from 'react'
import styles from 'css/modules/MilesToDays.module.css'

const MilesToDays = ({ miles }) => {
  const milesPerDay = 500 // TODO -- get this from TW (specific to carrier)
  // 1550 -> 3.1 days
  let days
  try {
    days = Math.round(10*(miles/milesPerDay))/10
  } catch (error) {
    return <></>
  }
  if (days === 0) {
    return <></>
  }
  return (
  <div className={styles.mainContainer}>
    {`${days} days`}
  </div>)
}

export default MilesToDays