import React, { useContext, useState, useEffect } from 'react'
import { Dialog, FormGroup, InputGroup, Button, Classes, Intent, Checkbox } from '@blueprintjs/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Auth from 'utils/Auth'
import { SettingsContext } from 'contexts/SettingsContext'
import Robot from 'images/robot.svg'
import LoadBuddyLogo from 'images/load_buddy_logo.svg'
import DemoButton from 'images/planet_purple_orbit.svg'
import Jupiter from 'images/jupiter.svg'
import styles from 'css/modules/Login.module.css'

const Login = ({setLoggedIn}) => {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [carrier, setCarrier] = useState("")
  const [carrierFull, setCarrierFull] = useState("")
  const [loginOpen, setLoginOpen] = useState(false)
  const [rememberMe, setRememberMe] = useState(localStorage.getItem('rememberMe') === "true")
  const [loggingIn, setLoggingIn] = useState(false)
  const [loginMessage, setLoginMessage] = useState(null)
  const [dialogOpen, setDialogOpen] = useState(false)
  const { settings } = useContext(SettingsContext)
  
  const _logIn = async () => {
    setLoginMessage(null)
    setLoggingIn(true)
    
    let response = await Auth.getInstance().logIn({ carrier, username, password })
    if (response.error) {
      setLoggingIn(false)
      setLoginMessage(response.message)
    } else {
      setLoggedIn(true)
    }
  }

  const _carrierOnChange = (e) => {
    let sCarrier = e.target.value;
    // Restricting Carrier to 4 Digits, but still allow longer length Carriers to be entered
    if(sCarrier.length > 4) {
      sCarrier = sCarrier.substring(0, 4);
    }
    setCarrierFull(e.target.value);
    setCarrier(sCarrier);
  }

  const submitOnEnter = (e) => {
    if ((e.key === 'Enter') && username && password && carrier) {
      _logIn()
    }
  }

  const toggleRememberMe = (e) => {
    if(!rememberMe) {
      localStorage.setItem('rememberMe', 'true')
      setRememberMe(true)
    } else {
      localStorage.setItem('rememberMe', 'false')
      setRememberMe(false)
    }
  }


  // Uncomment to bring back demo sign in logic
  // const signInDemo = () => {
  //   fetch(`${settings.AUTH_URL}/demo`, {
  //     method: 'POST'
  //   })
  //   .then(res => res.json())
  //   .then(response => {
  //     if(!response.error) {
  //       localStorage.clear()
  //       let domainWithoutQueryParams = window.location.href.split('?')[0]
  //       let redirectURL = `${domainWithoutQueryParams}?guid=${response.guid}&orig=Chicago,%20IL`
  //       window.location.replace(redirectURL)
  //     }
  //   })
  // }
  
  return (
    <div className={styles.windowContainer}>
      <div className={styles.mainBodyContainer}>
        <div className={styles.advertLeft}>
          <p className={styles.advertLeftHeader}>Free Load Posting</p>
          <p>Free and <br /> always will be.</p>
        </div>
        <div className={styles.mainLogoContainer}>
          <div className={styles.mainLogoGrid}>
            <div className={styles.robotImgContainer}>
              <img className={styles.robotImg} src={Robot} />
            </div>
            <div className={styles.loadBuddyLogoContainer}>
              <img className={styles.loadBuddyLogo} src={LoadBuddyLogo} />
            </div>
          </div>
          <div className={styles.loginControlsGrid}
              style={{ display: loginOpen && 'none' }}>
            <div className={styles.title}>
                Virtual Load Board
            </div>
            <div className={styles.titleBlurb}>
                Search Engine
            </div>
            <div className={styles.signInButtonContainer}
                onClick={() => {setLoginOpen(true)}}>
              <div className={styles.loginTextContainerTop}>
                SIGN IN
              </div>
              <div>
                <img className={styles.jupiterButtonImg} src={Jupiter} />
              </div>
            </div>
            <div className={styles.demoButtonContainer}
              onClick={()=>setDialogOpen(true)}>
              <div className={styles.loginTextContainerTop}>
                FREE
              </div>
              <div>
                <img className={styles.demoButtonImg} src={DemoButton} />
              </div>
              <div className={styles.loginTextContainerBottom}>
                MARKET RATES
              </div>
            </div>
          </div>
          { loginOpen && 
            <div className={styles.loginContainer}>
              <FormGroup
                labelFor="carrier-input">
                <InputGroup
                  placeholder="Member"
                  id="carrier-input"
                  value={carrierFull}
                  disabled={loggingIn}
                  onChange={(e) => _carrierOnChange(e)}
                  onKeyDown={submitOnEnter}
                />
              </FormGroup>
              <FormGroup
                labelFor="username-input">
                <InputGroup
                  className={styles.textInput}
                  placeholder="Username"
                  id="username-input"
                  value={username}
                  disabled={loggingIn}
                  onChange={(e) => setUsername(e.target.value)}
                  onKeyDown={submitOnEnter}
                />
              </FormGroup>
              <FormGroup
                labelFor="password-input">
                <InputGroup
                  className={styles.textInput}
                  placeholder="Password"
                  id="password-input"
                  type="password"
                  value={password}
                  disabled={loggingIn}
                  onChange={(e) => { setPassword(e.target.value) }}
                  onKeyDown={submitOnEnter}
                />
              </FormGroup>
              <FormGroup
                className={styles.rememberMeContainer}
                label={'Remember Me'}
                labelFor={'remember-me'}
                inline={true}>
                  <Checkbox id={'remember-me'} checked={rememberMe} onClick={toggleRememberMe} />
              </FormGroup>
              <Button
                className={styles.button}
                text={"Sign in"}
                intent={Intent.PRIMARY}
                disabled={!username || !password || !carrier || loggingIn}
                onClick={() => _logIn()}
                loading={loggingIn}
              />
              { loginMessage && 
                <div style={{
                paddingTop: 10
              }}>
                <span style={{
                  color: 'red'
                }}>
                  { loginMessage }
                </span>
              </div>
              }
            </div>
          }
        </div>
        <div className={styles.advertRight}>
          <p>-What's Best</p>
          <p>-What Rate</p>
          <p>-Get Me Back</p>
        </div>
      </div>
      <div className={styles.contactUsBanner}>
        <div className={styles.contactUsLink}
             onClick={()=>setDialogOpen(true)}>
          Contact Us
        </div>
        <Dialog
          className={styles.contactUsDialog}
          isOpen={dialogOpen}
          onClose={()=>setDialogOpen(false)}
          title={"Contact Us"}
          usePortal={true}>
          <div className={[Classes.DIALOG_BODY, styles.contactUsDialogBody].join(' ')}>
            <div className={[styles.contactNameFirst, styles.contactName].join(' ')}>
              Matt Garrett
            </div>
            <div className={[styles.contactDetailsFirst, styles.contactDetails].join(' ')}>
              <FontAwesomeIcon icon='phone' style={{marginRight: 5}}></FontAwesomeIcon>
              <a href='tel:4794664217'>(479) 466-4217</a>
            </div>
            <div className={[styles.contactNameSecond, styles.contactName].join(' ')}>
              Gwen Moore
            </div>
            <div className={[styles.contactDetailsSecond, styles.contactDetails].join(' ')}>
              <FontAwesomeIcon icon='envelope' style={{marginRight: 5}}></FontAwesomeIcon>
              <a href='mailto:gwen@loadbuddy.ai'>gwen@loadbuddy.ai</a>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  )
}

export default Login