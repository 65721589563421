import React, { useContext } from 'react'
import ActiveMarketButton from './ActiveMarketButton'
import _ from 'lodash'
import { LoadFilterContext } from 'contexts/LoadFilterContext'
import { LoadSearchContext } from 'contexts/LoadSearchContext'
import styles from 'css/modules/ActiveMarketFilterControl.module.css'

const ActiveMarketFilterControl = ({
  loads,
  activeMarkets,
  toggleLoadsView,
  showAllMarkets,
  showOnlyActiveMarkets,
  observationsThreshold,
  setObservationsThreshold,
  destOnly,
  routeLoadsNoResults }) => {
  
    const { filterExceptBy } = useContext(LoadFilterContext)
    const { search, hasRouteSearch, hasDestOnlySearch } = useContext(LoadSearchContext)

  const loadPodField = destOnly ? 'origin_low_pod' : 'destination_low_pod'
  const getFilteredLoadPodName = (l) => {
    return destOnly ? l.origin_low_pod.name : l.destination_low_pod.name
  }
  // group loads by destination pod
  const filteredLoads = filterExceptBy(loads, loadPodField)
  let pods = _.uniqBy(filteredLoads, `${loadPodField}.name`)
  let activeMarketList = activeMarkets.map(l => l.pod)
  
  // Scores that are < -7500 have been artificially lowered on TW
  // Filter out!
  const LOWEST_SCORE_THRESHOLD = -7500
  // pods = pods.filter(l => {
  //   return (
  //     (l.tw_final_score !== null && l.tw_final_score > LOWEST_SCORE_THRESHOLD) || 
  //     (l.tw_final_score === null && l.expected_score !== null && l.expected_score > LOWEST_SCORE_THRESHOLD)
  //   )
  // })

  pods = pods.filter(l => activeMarketList.includes(getFilteredLoadPodName(l)))

  // Pluck out just the destination pods
  pods = pods.map(l => getFilteredLoadPodName(l)).sort()

  // if (observationsThreshold !== 0) {
  //   pods.push('EXPAND') // Expands pods view to all available pods
  // }

  const createActiveMarketButton = (pod) => {
    if (pod === 'EXPAND') {
      // Special case for ALL button
      return <ActiveMarketButton onClick={() => showAllMarkets()} key={pod} pod={pod} maxScore={null} toggleLoadsView={toggleLoadsView} loadPodField={loadPodField} />
    }
    
    let _loads = [];
    if(!hasRouteSearch() || routeLoadsNoResults) {
      // Extract all loads in this pod
      _loads = filteredLoads.filter(l => getFilteredLoadPodName(l) === pod)
    } else {
      _loads = _.filter(loads, function(obj) { return obj.destination_low_pod.name === pod; });
    }

    // Pull out list of scores based on quotes and offers
    const twScoredOffers = _.map(_loads.filter(load => load.tw_final_score !== null), 'tw_final_score')
    const expectedScores = _.map(_loads.filter(load => load.tw_final_score === null && load.expected_score !== null), 'expected_score')

    // Combine all scores and find the max
    const maxScore = Math.max(...(twScoredOffers.concat(expectedScores)))
    const scoreValid = isFinite(maxScore)
    return (
      <ActiveMarketButton key={pod} pod={pod} maxScore={scoreValid && maxScore} toggleLoadsView={toggleLoadsView} loadPodField={loadPodField} />
    )
  }

  if(hasRouteSearch() && !routeLoadsNoResults) {
    return (
      <div className={styles.mainContainer}>
        {
         createActiveMarketButton(search.destPod)
        }
      </div>
    )
  }
  
  return (
    <div className={styles.mainContainer}>
      {
        pods.map(pod => createActiveMarketButton(pod))
      }
    </div>
  )
}

export default ActiveMarketFilterControl