import React, { useState } from 'react'
import { FormGroup, InputGroup, Button, Intent, Checkbox } from '@blueprintjs/core'
import AdminAuth from 'utils/admin/AdminAuth'
import styles from 'css/modules/Login.module.css'

const AdminLogin = ({setLoggedIn}) => {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [rememberMe, setRememberMe] = useState(localStorage.getItem('rememberMe') === "true")
  const [loggingIn, setLoggingIn] = useState(false)
  const [loginMessage, setLoginMessage] = useState(null)
  
  const _logIn = async () => {
    setLoginMessage(null)
    setLoggingIn(true)
    let response = await AdminAuth.getInstance().logIn({ "carrier":"ADMIN", username, password });
    if (response.error) {
      setLoggingIn(false)
      setLoginMessage(response.message)
    } else {
      setLoggedIn(true)
    }
  }

  const submitOnEnter = (e) => {
    if ((e.key === 'Enter') && username && password) {
      _logIn()
    }
  }

  const toggleRememberMe = (e) => {
    if(!rememberMe) {
      localStorage.setItem('rememberMe', 'true')
      setRememberMe(true)
    } else {
      localStorage.setItem('rememberMe', 'false')
      setRememberMe(false)
    }
  }

  return (
    <div className={styles.windowContainer}>
      <div className={styles.mainLogoContainer}>
        <div className={styles.loginContainer}>
          <FormGroup
            labelFor="carrier-input">
            <InputGroup
              placeholder="Member"
              id="carrier-input"
              value={"ADMIN"}
              disabled={"true"}
              onKeyDown={submitOnEnter}
            />
          </FormGroup>
          <FormGroup
            labelFor="username-input">
            <InputGroup
              className={styles.textInput}
              placeholder="Username"
              id="username-input"
              value={username}
              disabled={loggingIn}
              onChange={(e) => setUsername(e.target.value)}
              onKeyDown={submitOnEnter}
            />
          </FormGroup>
          <FormGroup
            labelFor="password-input">
            <InputGroup
              className={styles.textInput}
              placeholder="Password"
              id="password-input"
              type="password"
              value={password}
              disabled={loggingIn}
              onChange={(e) => { setPassword(e.target.value) }}
              onKeyDown={submitOnEnter}
            />
          </FormGroup>
          <FormGroup
              className={styles.rememberMeContainer}
              label={'Remember Me'}
              labelFor={'remember-me'}
              inline={true}>
                <Checkbox id={'remember-me'} checked={rememberMe} onClick={toggleRememberMe} />
            </FormGroup>
          <Button
            className={styles.button}
            text={"Sign in"}
            intent={Intent.PRIMARY}
            disabled={!username || !password || loggingIn}
            onClick={() => _logIn()}
            loading={loggingIn}
          />
          <div style={{
            paddingTop: 10
          }}>
            <span style={{
              color: 'red'
            }}>
              { loginMessage }
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminLogin