import React, { useState, useEffect, useContext } from 'react'
import { debounce } from 'throttle-debounce'
import { TWWebServicesContext } from 'contexts/TWWebServicesContext'
import _ from 'lodash'

const FetchSearchFormMilesAndExpected = (props) => {
  const {
    empty,
    origin,
    originPod,
    destination,
    division,
    study,
    equipment,
    resolution,
    locationRegex } = props
  const { twScoreLoad, getSpotIndicatorPct } = useContext(TWWebServicesContext)
  const initData = {
    emptyMiles: '',
    ldedMiles: '',
    expected: '',
    quoteTarget: '',
    baseline: '',
    originPod: '',
    destPod: '',
    emptyPod: '',
    fuelSurcharge: '',
    marketRate: '',
    marketRateLow: '',
    marketRateHigh: '',
    confidence: null
  }
  const [data, setData] = useState(initData)
  const [loading, setLoading] = useState(false)

  // Prop validation
  const emptyBlank =        empty === ''
  const originBlank =       origin === ''
  const emptyReady =        (_.isString(empty) && (empty.match(locationRegex) !== null)) || emptyBlank
  const originReady =       (_.isString(origin) && (origin.match(locationRegex) !== null)) || (originBlank && !emptyBlank && emptyReady)
  const originPodReady =    _.isString(originPod) || originBlank
  const destinationReady =  _.isString(destination) && (destination.match(locationRegex) !== null)
  const divisionReady =     _.isString(division)
  const studyReady =        _.isString(study)
  const equipmentReady =    _.isString(equipment)
  const resolutionReady =   Number.isInteger(resolution)

  // Only fire off score fetch when we good to go...
  const scoreRequestReady = (
    emptyReady &&
    originReady &&
    originPodReady &&
    destinationReady &&
    divisionReady &&
    studyReady &&
    equipmentReady &&
    resolutionReady
  )

  // Helper function for parsing City, ST
  const tryExtract = (str, idx) => {
    try {
      return str.split(',')[idx].trim().toUpperCase()
    }
    catch {  }
    return ''
  }
  const LOC = { CITY: 0, STATE: 1 }

  const shownEmpty = (() => {
    if (emptyBlank) {
      return ''
    }
    if (originReady && emptyReady) {
      if ((tryExtract(empty, LOC.STATE) === tryExtract(origin, LOC.STATE)) &&
         (tryExtract(empty, LOC.CITY) === tryExtract(origin, LOC.CITY))) {
          return 0
      }
    } 
    return data.emptyMiles || ''
  })()

  // Debounced because outbound requests triggered by user input (1 sec)
  const debouncedFetch = debounce(500,
    (twScoreArgs) => {
      setLoading(true)
      twScoreLoad(twScoreArgs).then(data => {
        if (data !== null) {
          //console.log(getSpotIndicatorPct(originPod, equipment))
          const {
            miles_empty,
            miles_loaded,
            market,
            marketlow,
            markethigh,
            market_withfuel,
            targetlinehaul_withfuel,
            indifference_withfuel,
            originpod,
            destinationpod,
            emptypod,
            confidence,
            fuel_surcharge
          } = data
          setData({
            emptyMiles: miles_empty,
            ldedMiles: miles_loaded,
            expected: confidence > 2 ? Math.round(market_withfuel * getSpotIndicatorPct(originPod, equipment)) : 'N/A',
            quoteTarget: 0, //targetlinehaul_withfuel, (Zero'd out)
            baseline: indifference_withfuel,
            originPod: originpod,
            destPod: destinationpod,
            emptyPod: emptypod,
            fuelSurcharge: fuel_surcharge,
            marketRate: market,
            marketRateLow: marketlow,
            marketRateHigh: markethigh,
            confidence: confidence
          })
          setLoading(false)
        } else {
          setData(initData)
          setLoading(false)
        }
      })
  })

  // Translates the prop inputs of this component
  // into arguments for the TW Load Scoring function.
  // Load scoring returns everything we need (miles and expected)
  const generateTWScoreArgs = () => {
    return {
      truckCity: tryExtract(empty, LOC.CITY), // Use origin as emty if no emty for score
      truckState: tryExtract(empty, LOC.STATE),
      loadOriginCity: tryExtract((originBlank ? empty : origin), LOC.CITY), // Use empty as origin when no origin but has empty
      loadOriginState: tryExtract((originBlank ? empty : origin), LOC.STATE),
      loadDestinationCity: tryExtract(destination, LOC.CITY),
      loadDestinationState: tryExtract(destination, LOC.STATE),
      division,
      study,
      equipment,
      resolution
    }
  }

  useEffect(() => {
    if (scoreRequestReady) {
      debouncedFetch(generateTWScoreArgs())
    } else {
      setData(initData)
    }
  }, [
    empty,
    origin,
    destination,
    division,
    study,
    equipment,
    resolution,
    originPod
  ])

  const confidenceMsg = (() => {
    const confidence = data.confidence
    if(confidence) {
      if (confidence >= 3 && confidence <= 4) {
        return "Few Observations"
      }
      if (confidence >= 5 && confidence <= 6) {
        return "Active Lane"
      }
      if (confidence >= 7) {
        return "Highest Confidence"
      }
    }
    return "Insufficient Statistics"
  })()

  return (
    <>
      {
        props.children({
          emptyMiles: shownEmpty,
          ldedMiles: data.ldedMiles || '',
          expected: data.expected || '',
          quoteTarget: data.quoteTarget || '',
          baseline: data.baseline || '',
          loadingMilesAndExpected: loading,
          originInputFieldPod: data.originPod || '',
          destInputFieldPod: data.destPod || '',
          emptyInputFieldPod: data.emptyPod ||'',
          fuelSurcharge: data.fuelSurcharge || '',
          marketRate: data.marketRate || '',
          marketRateLow: data.marketRateLow || '',
          marketRateHigh: data.marketRateHigh || '',
          confidenceMsg: confidenceMsg
        })
      }
    </>
  )
}

export default FetchSearchFormMilesAndExpected