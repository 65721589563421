import React, { useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import AdminUtil from 'utils/admin/AdminUtil'
import UserHits from './UserHits'
import styles from 'css/modules/UserActivity.module.css'
import moment from 'moment'

const thirtyDaysAgo = moment().utc().add(-1, 'month').endOf('day').format("YYYY-MM-DD")
const USER_ACTIVITY = gql`{
  users(order_by: {refresh_token: {last_active: desc_nulls_last}}) {
    user_events(where: {created_at: {_gte: "${thirtyDaysAgo}"}}) {
      data
      event_type
      created_at
    }
    refresh_token {
      last_active
    }
    username
    carrier {
      code
    }
  }
}`

const SORT_ORDER = {
  LAST_ACTIVE: 0,
  CARRIERS: 1,
  HITS: 2
}

const UserActivity = () => {
  const [sorting, setSorting] = useState(SORT_ORDER.LAST_ACTIVE)
  const [searchTerm, setSearchTerm] = useState('')
  const { loading, error, data } = useQuery(USER_ACTIVITY, {
    pollInterval: 300000 // 5 minute update interval
  })
  if (loading) {
    return 'Loading'
  }
  if (error) {
    return 'Error'
  }

  const isOnlineNow = (user) => {
    return moment.utc(user.last_active).local().isAfter(moment().subtract(15, 'minutes'))
  }

  const rawTimeStampFormat = (last_active) => {
    let relativeDay = null
    // today and yesterday
    let today = moment.utc(last_active).local().isAfter(moment().local().add(-1, 'day').endOf('day'))
    let yesterday = moment.utc(last_active).local().isAfter(moment().local().add(-2, 'days').endOf('day'))
    if (yesterday) {
      relativeDay = "Yesterday"
    } 
    if (today) {
      relativeDay = "Today"
    }
    if (relativeDay === null) {
      relativeDay = moment.utc(last_active).local().format('MM/DD/YY')
    }
    return `${relativeDay} ${moment.utc(last_active).local().format('hh:mma')}`
  }

  const UserActivityRow = ({user}) => {
    return (
      <div
        className={styles.userActivityRow}>
        <div className={styles.carrierField}>
          {user.carrier}
        </div>
        <div className={styles.usernameField}>
          {user.username}
        </div>
        <div className={styles.timeStampField}>
          <div className={styles.timeStampRawText}>
            {
              isOnlineNow(user) ?
                <span className={styles.onlineNow}>
                  Online
                </span>
                :
                rawTimeStampFormat(user.last_active)
            }
          </div>
          { /** <div className={styles.timeStampTimeAgo}>
            {
              !isOnlineNow(user) && <TimeAgo timestamp={user.last_active} />
            }
          </div> **/ }
        </div>
        <div
          className={styles.hitsField}>
          <UserHits
            events={user.user_events}
            eventType={'search'}
            startDate={moment().local().startOf('day')}
            endDate={moment().local().endOf('day')} />
        </div>
        <div
          className={styles.hitsField}>
          <UserHits
            events={user.user_events}
            eventType={'search'}
            startDate={moment().local().add(-1, 'week').startOf('day')}
            endDate={moment().local().endOf('day')} />
        </div>
        <div
          className={styles.hitsField}>
          <UserHits
            events={user.user_events}
            eventType={'search'}
            startDate={moment().local().add(-1, 'month').startOf('day')}
            endDate={moment().local().endOf('day')} />
        </div>
      </div>
    )
  }

  const orderData = (data) => {
    switch (sorting) {
      case SORT_ORDER.LAST_ACTIVE:
        return AdminUtil().sortByLastActive(data)
      case SORT_ORDER.CARRIERS:
        return AdminUtil().sortByCarriers(data)
      default:
        return AdminUtil().sortByLastActive(data)
    }
  }

  const filterData = (data) => {
    if (searchTerm === "") {
      return data
    }
    let searchTermRegex =  new RegExp(`${searchTerm.toUpperCase()}`, 'g')
    return data.filter(user => user.carrier.match(searchTermRegex))
  }
  return (
    <div className={styles.mainContainer}>
      <div className={styles.headerRow}>
        <input
          style={{
            width: 75
          }}
          type={'text'}
          onKeyUp={(e) => setSearchTerm(e.target.value)}
          placeholder={'Search'} />
      </div>
      <div className={styles.headerRow}>
        <div 
          className={[styles.carrierField, styles.headerText, styles.clickable].join(' ')}
          onClick={() => setSorting(SORT_ORDER.CARRIERS)}>
          CARRIER
        </div>
        <div className={[styles.usernameField, styles.headerText].join(' ')}>
          USER
        </div>
        <div
          className={[styles.timeStampField, styles.headerText, styles.clickable].join(' ')}
          onClick={() => setSorting(SORT_ORDER.LAST_ACTIVE)}>
          LAST ACTIVE
        </div>
        <div
          className={[styles.hitsField, styles.headerText].join(' ')}>
            HITS
        </div>
        <div
          className={[styles.hitsField, styles.headerText].join(' ')}>
            HIT7
        </div>
        <div
          className={[styles.hitsField, styles.headerText].join(' ')}>
            HIT30
        </div>
      </div>
      { filterData(orderData(data)).map(user => <UserActivityRow user={user} key={JSON.stringify(user)} />) }
    </div>
  )
}

export default UserActivity