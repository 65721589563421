import React, { Fragment, useEffect } from 'react'
import StarImg from 'images/star.svg'
import StarDeactivatedImg from 'images/star_deactivated.svg'
import styles from 'css/modules/StarRating.module.css'
import _ from "lodash";

const StarRating = ({stars}) => {
  const MAX_STARS = 5;
  let _activeStars = 0, _emptyStars = 0;
  
  // Calculate the amount of Active and Empty Stars
  const _CalculateStars = () => {
    stars = stars || 0;
    if(stars > MAX_STARS) { stars = MAX_STARS; }
    _activeStars = stars;
    _emptyStars = MAX_STARS - stars;
  };
  _CalculateStars();
  
  const ActiveStars = ({starCount}) => {
    return _.range(starCount).map((_n, star) => <img className={styles.starImg} src={StarImg} alt={'Star'} key={`${star}Active`} />);
  }

  const EmptyStars = ({starCount}) => {
    return _.range(starCount).map((_n, star) => <img className={styles.starImg} src={StarDeactivatedImg} alt={'Empty Star'} key={star} />);
  }

  return (
    <div className={styles.mainContainer}>
      <ActiveStars starCount={_activeStars} />
      <EmptyStars starCount={_emptyStars} />
    </div>
  )
}

export default StarRating