import React, { useState, createContext, useContext } from 'react'
import moment from 'moment'

export const LoadSearchContext = createContext()

export const LoadSearchProvider = props => {
  const initSearchObj = {
    truck: null,
    equipment: null,
    dateStart: null,
    dateEnd: null,
    quotesOnly: true,
    minLOH: 0,
    pods: [],
    division: null,
    study: null
  }
  const [search, _setSearch] = useState(initSearchObj)

  const setSearch = (truck, extraSearchParams) => {
    const { adjacentPods, tractor, trailer, driver, equipment, city, zip, state, ptaDate, orderNum, podIdLow, destination } = truck
    const { quotesOnly, minLOH, study, division, resolution, origPod, destPod } = extraSearchParams
    let equipmentSearch = {}
    switch(equipment) {
      case "V":
        equipmentSearch = {equipment_category: {_like: "%V%" }}
        break;
      case "R":
        //equipmentSearch = {equipment_category: {_like: "%R%" }}
        equipmentSearch = {_or: [
          {equipment_category: {_like: "%R%" }},
          {equipment_category: {_like: "%V%" }}
        ]};
        break;
      case "F":
        equipmentSearch = {equipment_category: {_like: "%F%" }}
        break;
      default:
        equipmentSearch = {equipment_category: {_like: "%V%" }}
    }
    let destPods
    // IMPORTANT. UNCOMMENT THIS SECTION TO BRING BACK DESTINATION FILTERING
    // ON LOADS AT THE DB LEVEL! Destination-only search
    if (truck.destination) {
      let { destAdjacentPods, destPod, destPodIdLow } = truck.destination
      destPods = [destPodIdLow, ...destAdjacentPods]
    }

    let searchObj = {
      truck,
      equipment: equipmentSearch,
      dateStart: moment(ptaDate).format('YYYY-MM-DD'),
      dateEnd: moment(ptaDate).add(5, 'days').format('YYYY-MM-DD'),
      pods: podIdLow !== null ? [podIdLow, ...adjacentPods] : null,
      destPods,
      origPod: origPod,
      destPod: destPod,
      quotesOnly,
      minLOH,
      study,
      division,
      resolution,
      truckEquipment: equipment,
      searchTimestamp: moment().format('MMMM Do YYYY, h:mm:ss a')
    }
    _setSearch(searchObj)
  }

  const hasRouteSearch = () => {
    return search.truck !== null && search.truck.destCitySt !== null && search.truck.destCitySt !== '' && search.truck.city !== null && typeof search.truck.city !== "undefined";
  }

  const _emptySearch ={
    equipment: null,
    dateStart: null,
    podId: null
  }

  // Keeps previous search state EXCEPT for some items that will reset the search
  // (clears out the loads when called to get ready for next search)
  const clearSearch = () => {
    _setSearch({
      ...search,
      ..._emptySearch
    })
  }

  const searchEmpty = () =>
    (search.equipment === null ||
    search.dateStart === null ||
    search.podId === null)

  const hasDestOnlySearch = () => {
      return search.truck !== null && search.truck.destination !== null;
  }

  return (
    <LoadSearchContext.Provider value={{
      search,
      setSearch,
      clearSearch,
      searchEmpty,
      hasDestOnlySearch,
      hasRouteSearch
    }}>
      { props.children }
    </LoadSearchContext.Provider>
  )
}

