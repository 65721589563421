// Handler for route /admin
import React, { useContext, useEffect, useState } from 'react'
import ApolloClient from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloProvider } from '@apollo/react-hooks'
import { WebSocketLink } from 'apollo-link-ws'
import AdminLogin from 'components/admin/AdminLogin'
import AdminAppHeader from 'components/admin/AdminAppHeader'
import AdminAuth from 'utils/admin/AdminAuth'
import UserActivity from 'components/admin/UserActivity'
import { AppStyleContext } from 'contexts/AppStyleContext'
import styles from 'css/modules/App.module.css'

/// ## Important!!! ##
/// Needed in the server.js
/// app.use('/admin', express.static(path.join(__dirname, '..', 'build')))

// Web Socket link used within the entire application for API access
const webSocketLink = new WebSocketLink({
  uri: `${process.env.REACT_APP_WEBSOCKET_URL}`,
  options: {
    reconnect: true,
    lazy: true,
    // connectionParams set to a function here so that every request uses the most recent
    // token returned from LB backend via autorefresh
    connectionParams: () => ({
      headers: {
        Authorization: AdminAuth.getInstance().getToken()
      }
    })
  }
})

const createApolloClient = () => {
  return new ApolloClient({
    link: webSocketLink,
    cache: new InMemoryCache(),
  })
}

const AdminRoute = () => {
    
    const { appStyle } = useContext(AppStyleContext)
    const [loggedIn, setLoggedIn] = useState(false)
    let authToken = AdminAuth.getInstance().getToken()
    const notLoggedIn = (!authToken || !loggedIn)
    const client = createApolloClient()
    const trySessionOrGUIDLogin = notLoggedIn && 
                (localStorage.getItem('refreshToken') 
                    && (localStorage.getItem('rememberMe') 
                    && localStorage.getItem('rememberMe') === "true")
                );
  
    // Initialize authorization callbacks to handle what happens to this component state when
    // login succeeds or fails (show login modal or don't)
    AdminAuth.getInstance().setCallbacks({
      success: () => {
        if (!loggedIn) {
          setLoggedIn(true)
        }
      },
      logOutUI: () => {
        setLoggedIn(false)
      }
    })
  
    useEffect(() => {
      if (trySessionOrGUIDLogin) {
        AdminAuth.getInstance().tryLocalStorageLogin(false)
      }
    }, [loggedIn])
    
    
    if (notLoggedIn) {
      return (
        <AdminLogin setLoggedIn={setLoggedIn}/>
      )
    }

    return (
      <ApolloProvider client={client}>
        <div 
          className={styles.mainContainer}
          style = {{
            color: appStyle.color.text,
            backgroundColor: appStyle.color.background.main,
            fontSize: appStyle.text.regular
          }}>
          <AdminAppHeader />
          <div className={styles.searchContainer}>
            <UserActivity />
          </div>
        </div>
      </ApolloProvider>
    )
  }

export default AdminRoute