import React, { useState, createContext } from 'react'

export const TrucksContext = createContext()

export const TrucksProvider = props => {
  const [searchTerm, setSearchTerm] = useState("")
  
  const filterTrucksWithSearch = (trucks) => {
    let filteredTrucks = []
    let searchTermRegex = new RegExp(`(${searchTerm.toUpperCase()})`, 'g')
    trucks.forEach(truck => {
      let stringifiedTruck = JSON.stringify(truck)
      if(stringifiedTruck.match(searchTermRegex)) {
        filteredTrucks.push(truck)
      }
    })
    return filteredTrucks
  }

  return (
    <TrucksContext.Provider value={{ filterTrucksWithSearch, searchTerm, setSearchTerm }}>
      { props.children }
    </TrucksContext.Provider>
  )
}

